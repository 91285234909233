import api from '@/api';
import { VOUCHERS_SERVICE } from './constants';

export default {
  vouchersGet: async (params: any) => {
    return await api.get(`/${VOUCHERS_SERVICE}/vouchers/`, {
      params,
    });
  },
  vouchersSearch: async (params: any) => {
    return await api.get(`/${VOUCHERS_SERVICE}/vouchers/`, {
      params,
    });
  },
  vouchersGetOne: async ({ id }: any) => {
    return await api.get(`/${VOUCHERS_SERVICE}/vouchers/${id}/`);
  },
  vouchersSet: async (params: any) => {
    return await api.post(`/${VOUCHERS_SERVICE}/vouchers/`, params);
  },
  vouchersUpdate: async ({ id, ...params }: any) => {
    return await api.patch(`/${VOUCHERS_SERVICE}/vouchers/${id}/`, params);
  },
  vouchersDelete: async (params: Record<string, unknown>) => {
    return await api.delete(`/${VOUCHERS_SERVICE}/vouchers/${params.id}/`);
  },
  vouchersActivate: async (params: Record<string, unknown>) => {
    return await api.post(`/${VOUCHERS_SERVICE}/vouchers/activate/`, params);
  },
};
