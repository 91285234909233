export default {
  name: '',
  path: 'currencies',
  component: () => import('@/views/currencies/CurrenciesIndex.vue'),
  children: [
    {
      path: '',
      name: 'currencies',
      component: () => import('@/views/currencies/Currencies.vue'),
      props: true,
      meta: {
        module: 'currencies',
        breadcrumbs: [{ text: 'routes.currencies', exact: true, to: { name: 'currencies' }, disabled: true }],
      },
    },
    {
      name: 'currenciesCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/currencies/CurrenciesCreate.vue'),
      meta: {
        module: 'currencies',
        breadcrumbs: [
          { text: 'routes.currencies', exact: true, to: { name: 'currencies' }, disabled: false },
          { text: 'routes.currency_create' },
        ],
      },
    },
    {
      name: 'currenciesUpdate',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/currencies/CurrenciesUpdate.vue'),
      meta: {
        module: 'currencies',
        breadcrumbs: [
          { text: 'routes.currencies', exact: true, to: { name: 'currencies' }, disabled: false },
          { text: 'routes.currency_edit' },
        ],
      },
    },
  ],
};
