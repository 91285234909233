export default {
  name: '',
  path: 'aml-quarantine',
  component: () => import('@/views/amlQuarantine/AmlQuarantineIndex.vue'),
  children: [
    {
      path: '',
      name: 'aml-quarantine',
      component: () => import('@/views/amlQuarantine/AmlQuarantine.vue'),
      props: true,
      meta: {
        breadcrumbs: [{ text: 'routes.aml_quarantine', exact: true, to: { name: 'aml-protocols' }, disabled: true }],
      },
    },
    {
      path: 'update/:id',
      name: 'aml-quarantine-update',
      component: () => import('@/views/amlQuarantine/AmlQuarantineUpdate.vue'),
      props: true,
      meta: {
        // module: 'reports',
        breadcrumbs: [
          { text: 'routes.aml_quarantine', exact: true, to: { name: 'aml-quarantine' } },
          { text: 'routes.aml_quarantine_update' },
        ],
      },
    },
  ],
};
