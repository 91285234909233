export default {
  name: '',
  path: 'exchange-currencies',
  component: () => import('@/views/exchangeCurrencies/ExchangeCurrenciesIndex.vue'),
  children: [
    {
      path: '',
      name: 'exchangeCurrencies',
      component: () => import('@/views/exchangeCurrencies/ExchangeCurrencies.vue'),
      props: true,
      meta: {
        module: 'exchangeCurrencies',
        breadcrumbs: [{ text: 'routes.currencies', exact: true, to: { name: 'exchangeCurrencies' }, disabled: true }],
      },
    },
    {
      name: 'exchangeCurrenciesCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/exchangeCurrencies/ExchangeCurrenciesCreate.vue'),
      meta: {
        module: 'exchangeCurrencies',
        breadcrumbs: [
          { text: 'routes.currencies', exact: true, to: { name: 'exchangeCurrencies' }, disabled: false },
          { text: 'routes.currency_create' },
        ],
      },
    },
    {
      name: 'exchangeCurrenciesUpdate',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/exchangeCurrencies/ExchangeCurrenciesUpdate.vue'),
      meta: {
        module: 'exchangeCurrencies',
        breadcrumbs: [
          { text: 'routes.currencies', exact: true, to: { name: 'exchangeCurrencies' }, disabled: false },
          { text: 'routes.currency_edit' },
        ],
      },
    },
  ],
};
