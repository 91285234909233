import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  paymentFormsGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/payment_forms/`, {
      params,
    });
  },
  paymentFormsSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/payment_forms/`, params);
  },
  paymentFormsUpdate: async ({ id, ...params }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/payment_forms/${id}/`, params);
  },
  paymentFormsDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/payment_forms/${id}/`);
  },
  paymentFormsSearch: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/payment_forms/search/`, {
      params,
    });
  },
};
