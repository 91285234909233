import api from '@/api';
import { EXCHANGE_OFFICE } from './constants';

export default {
  exchangeOrdersGet: async (params: any) => {
    return await api.get(`/${EXCHANGE_OFFICE}/orders/`, {
      params,
    });
  },
  exchangeOrderGet: async (params: any) => {
    return await api.get(`/${EXCHANGE_OFFICE}/orders/${params.id}/`);
  },
  exchangedOrdersFinalizeMethodsGet: async (params: any) => {
    return await api.get(`${EXCHANGE_OFFICE}/orders/${params.id}/finalize/`);
  },
  exchangedOrdersFinalize: async ({ id, ...params }: any) => {
    return await api.post(`${EXCHANGE_OFFICE}/orders/${id}/finalize/`, params);
  },
  exchangeOrderChangeStatus: async ({ id, ...params }: any) => {
    return await api.post(`${EXCHANGE_OFFICE}/orders/${id}/change_status/`, params);
  },
};
