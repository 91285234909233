import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  serviceFieldTemplatesGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/services/service_field_values_templates/`, {
      params,
    });
  },
  serviceFieldTemplatesSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/services/service_field_values_templates/`, params);
  },
  serviceFieldTemplatesUpdate: async ({ id, ...params }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/services/service_field_values_templates/${id}/`, params);
  },
  serviceFieldTemplatesDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/services/service_field_values_templates/${id}/`);
  },
  serviceFieldTemplateGet: async ({ id }: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/services/service_field_values_templates/${id}/`);
  },
  serviceFieldTemplatesSearch: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/services/service_field_values_templates/search/`, {
      params,
    });
  },

  serviceFieldValuesGet: async ({ templateId, ...params }: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/services/service_field_values_templates/${templateId}/values/`, {
      params,
    });
  },
  serviceFieldValuesSet: async ({ templateId, formData }: any) => {
    return await api.post(
      `/${DEFAULT_SERVICE}/services/service_field_values_templates/${templateId}/values/`,
      formData,
      {
        headers: {
          'Content-Type': `multipart/form-data`, // boundary=${formData._boundary}
        },
      }
    );
  },
  serviceFieldValuesUpdate: async ({ templateId, id, ...params }: any) => {
    return await api.put(
      `/${DEFAULT_SERVICE}/services/service_field_values_templates/${templateId}/values/${id}/`,
      params
    );
  },
  serviceFieldValuesDelete: async ({ templateId, id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/services/service_field_values_templates/${templateId}/values/${id}/`);
  },
};
