import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  journalsGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/users/journal/`, {
      params,
    });
  },
  journalGet: async (id: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/users/journal/${id}/`);
  },
};
