export default {
  name: '',
  path: 'payment-forms',
  component: () => import('@/views/paymentForms/PaymentFormsIndex.vue'),
  children: [
    {
      path: '',
      name: 'paymentForms',
      component: () => import('@/views/paymentForms/PaymentForms.vue'),
      props: true,
      meta: {
        module: 'paymentForms',
        breadcrumbs: [{ text: 'routes.payment_forms', exact: true, to: { name: 'paymentForms' }, disabled: true }],
      },
    },
    {
      name: 'paymentFormsCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/paymentForms/PaymentFormsCreate.vue'),
      meta: {
        module: 'paymentForms',
        breadcrumbs: [
          { text: 'routes.payment_forms', exact: true, to: { name: 'paymentForms' }, disabled: false },
          { text: 'routes.payment_forms_create' },
        ],
      },
    },
    {
      name: 'paymentFormsUpdate',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/paymentForms/PaymentFormsUpdate.vue'),
      meta: {
        module: 'paymentForms',
        breadcrumbs: [
          { text: 'routes.payment_forms', exact: true, to: { name: 'paymentForms' }, disabled: false },
          { text: 'routes.payment_forms_edit' },
        ],
      },
    },
  ],
  meta: {
    module: 'paymentForms',
  },
};
