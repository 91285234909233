export default {
  name: '',
  path: 'services',
  component: () => import('@/views/services/ServicesIndex.vue'),
  children: [
    {
      path: '',
      name: 'services',
      component: () => import('@/views/services/Services.vue'),
      props: true,
      meta: {
        module: 'services',
        breadcrumbs: [{ text: 'routes.services', exact: true, to: { name: 'services' }, disabled: true }],
      },
    },
    {
      name: 'servicesCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/services/ServicesCreate.vue'),
      meta: {
        module: 'services',
        breadcrumbs: [
          { text: 'routes.services', exact: true, to: { name: 'services' }, disabled: false },
          { text: 'routes.service_create' },
        ],
      },
    },
    {
      name: 'servicesUpdate',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/services/ServicesUpdate.vue'),
      meta: {
        module: 'services',
        breadcrumbs: [
          { text: 'routes.services', exact: true, to: { name: 'services' }, disabled: false },
          { text: 'routes.service_edit' },
        ],
      },
    },
  ],
};
