export default {
  name: '',
  path: 'roles',
  component: () => import('@/views/roles/RolesIndex.vue'),
  children: [
    {
      path: '',
      name: 'roles',
      component: () => import('@/views/roles/Roles.vue'),
      props: true,
      meta: {
        module: 'roles',
        breadcrumbs: [{ text: 'routes.roles', to: 'roles', disabled: true }],
      },
    },
    {
      name: 'rolesCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/roles/RolesCreate.vue'),
      meta: {
        module: 'roles',
        breadcrumbs: [
          { text: 'routes.roles', exact: true, to: { name: 'roles' }, disabled: false },
          { text: 'routes.role_create' },
        ],
      },
    },
    {
      name: 'rolesUpdate',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/roles/RolesUpdate.vue'),
      meta: {
        module: 'roles',
        breadcrumbs: [
          { text: 'routes.roles', exact: true, to: { name: 'roles' }, disabled: false },
          { text: 'routes.role_edit' },
        ],
      },
    },
  ],
};
