import { EventBus } from '@/helpers/eventBus';
import i18n from '@/i18n';
import { type SnackbarOptions } from 'vuetify-use-dialog/utils';

export const useSnackbar = () => {
  return {
    createSnackbar(options: SnackbarOptions) {
      EventBus.$emit('v-snackbar', options);
    },
    errorSnackbar(options: Partial<SnackbarOptions>) {
      EventBus.$emit('v-snackbar', {
        snackbarProps: { color: 'red', location: 'bottom right' },
        closeButtonText: i18n.global.t('common.close'),
        ...options,
      });
    },
    successSnackbar(options: Partial<SnackbarOptions>) {
      EventBus.$emit('v-snackbar', {
        snackbarProps: { color: 'green', location: 'bottom right' },
        closeButtonText: i18n.global.t('common.close'),
        ...options,
      });
    },
  };
};
