import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  merchantIpGet: async ({ id }: any) => {
    const merchant_id = id;
    return await api.get(`/${DEFAULT_SERVICE}/merchants/${merchant_id}/ip/`); // ${interpolateUrl(params)}
  },
  merchantIpUpdate: async ({ merchant_id, id, ...params }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/merchants/${merchant_id}/ip/${id}/`, params);
  },
  merchantIpDelete: async ({ merchant_id, id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/merchants/${merchant_id}/ip/${id}/`);
  },
  merchantIpGetOne: async ({ merchant_id, id }: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/merchants/${merchant_id}/ip/${id}/`);
  },
  merchantIpSet: async ({ merchant_id, ...params }: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/merchants/${merchant_id}/ip/`, params);
  },
};
