/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

declare global {
  interface Window {
    ClipboardItem: unknown;
    _locale: string;
    __app_base_url: string;
    __app_theme: string;
    __title: string; // Saved title from dm.json
  }
}

// Components
import App from '@/App.vue';

// Composables
import { createApp } from 'vue';

// Plugins
import { registerPlugins } from '@/plugins';
import api from '@/api';
import '@/styles/index.scss';

// Libs
import axios from 'axios';

const bootstrap = async () => {
  try {
    let settings = {
      title: import.meta.env.VITE_APP_LOCAL_TITLE || '',
      theme: 'default',
    };

    if (!location.host.includes('localhost') && !location.host.includes('127.0.0.1')) {
      const { data } = await axios.get('/dm.json', {
        baseURL: location.origin,
      });

      settings = data;
    }

    document.title = settings.title || '';
    window.__app_theme = settings.theme || 'default';
    window.__title = settings.title || '';

    // Setup
    const app = createApp(App);
    registerPlugins(app);
    app.mount('#app');
  } catch (error) {
    console.log('Error', error);
  }
};

bootstrap();

/**
 * Prevent scroll to change value on input[type=number]
 */
document.addEventListener('wheel', (evt) => {
  const target = evt.target as HTMLInputElement;
  if (target?.localName === 'input' && target.type === 'number') {
    target.blur();
  }
});
