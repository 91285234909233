import api from '@/api';
import { CRYPTO_GATEWAYS_SERVICE } from './constants';

export default {
  cryptoGatewayProvidersGet: async (params: any) => {
    return await api.get(`/${CRYPTO_GATEWAYS_SERVICE}/providers/`, {
      params,
    });
  },
  cryptoGatewayProvidersSearch: async (params: any) => {
    return await api.get(`/${CRYPTO_GATEWAYS_SERVICE}/providers/`, {
      params,
    });
  },
  cryptoGatewayProvidersSet: async (params: any) => {
    return await api.post(`/${CRYPTO_GATEWAYS_SERVICE}/providers/`, params);
  },
  cryptoGatewayProvidersUpdate: async ({ id, ...params }: any) => {
    return await api.patch(`/${CRYPTO_GATEWAYS_SERVICE}/providers/${id}/`, params);
  },
  cryptoGatewayProvidersDelete: async ({ id }: any) => {
    return await api.delete(`/${CRYPTO_GATEWAYS_SERVICE}/providers/${id}/`);
  },
  cryptoGatewayProviderGet: async ({ id }: any) => {
    return await api.get(`/${CRYPTO_GATEWAYS_SERVICE}/providers/${id}/`);
  },

  cryptoGatewayProviderBalances: async ({ id }: any) => {
    return await api.get(`/${CRYPTO_GATEWAYS_SERVICE}/providers/${id}/balances/`);
  },
  cryptoGatewayProviderCommissions: async ({ id, ...params }: any) => {
    return await api.get(`/${CRYPTO_GATEWAYS_SERVICE}/providers/${id}/commissions/`, { params });
  },
  cryptoGatewayProviderRates: async ({ id }: any) => {
    return await api.get(`/${CRYPTO_GATEWAYS_SERVICE}/providers/${id}/rates/`);
  },
  cryptoGatewayProvidersRegistryGet: async ({ id, ...params }: any) => {
    return await api.get(`/${CRYPTO_GATEWAYS_SERVICE}/providers/${id}/transactions/`, { params });
  },
};
