export default {
  name: '',
  path: 'aml-checks',
  component: () => import('@/views/amlChecks/AmlChecksIndex.vue'),
  children: [
    {
      path: '',
      name: 'aml-checks',
      component: () => import('@/views/amlChecks/AmlChecks.vue'),
      props: true,
      meta: {
        breadcrumbs: [{ text: 'routes.aml_checks', exact: true, to: { name: 'aml-checks' }, disabled: true }],
      },
    },
    {
      path: 'view/:id',
      name: 'aml-checks-view',
      component: () => import('@/views/amlChecks/AmlChecksView.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          { text: 'routes.aml_checks', exact: true, to: { name: 'aml-checks' } },
          { text: 'routes.aml_checks_view' },
        ],
      },
    },
  ],
};
