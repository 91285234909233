export default {
  name: '',
  path: 'invoicing',
  component: () => import('@/views/invoicing/InvoicingIndex.vue'),
  children: [
    {
      path: '',
      name: 'invoicing',
      component: () => import('@/views/invoicing/Invoicing.vue'),
      props: true,
      meta: {
        module: 'invoicing',
        breadcrumbs: [{ text: 'routes.invoicing', exact: true, to: { name: 'invoicing' }, disabled: true }],
      },
    },
    {
      name: 'invoicingCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/invoicing/InvoicingCreate.vue'),
      meta: {
        module: 'invoicing',
        breadcrumbs: [
          { text: 'routes.invoicing', exact: true, to: { name: 'invoicing' }, disabled: false },
          { text: 'routes.invoicing_create', disabled: true },
        ],
      },
    },
    {
      name: 'invoicingView',
      path: 'view/:id',
      props: true,
      component: () => import('@/views/invoicing/InvoicingView.vue'),
      meta: {
        module: 'invoicing',
        breadcrumbs: [
          { text: 'routes.invoicing', exact: true, to: { name: 'invoicing' }, disabled: false },
          { text: 'routes.invoicing_view', disabled: true },
        ],
      },
    },
  ],
  meta: {
    module: 'invoicing',
  },
};
