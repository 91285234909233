import api from '@/api';
import { TECH_PAYOUTS_SERVICE, DEFAULT_SERVICE } from './constants';

export default {
  techPayoutsNewGet: async (params: any) => {
    return await api.get(`/${TECH_PAYOUTS_SERVICE}/payouts/`, {
      params,
    });
  },
  techPayoutsNewSet: async (params: any) => {
    return await api.post(`/${TECH_PAYOUTS_SERVICE}/payouts/`, params);
  },
  techPayoutsNewUpdate: async (id: any, params: any) => {
    return await api.put(`/${TECH_PAYOUTS_SERVICE}/payouts/${id}`, params);
  },
  techPayoutNewGet: async (id: any) => {
    return await api.get(`/${TECH_PAYOUTS_SERVICE}/payouts/${id}/`);
  },
  techPayoutsNewWalletServicesSearch: async (params: { wallet_id: any; merchant_id: any }) => {
    return await api.get(`/${TECH_PAYOUTS_SERVICE}/wallets/services/${params.wallet_id}/${params.merchant_id}/`);
  },
  techPayoutNewServiceGet: async (params: { service_id: any }) => {
    return await api.get(`/${TECH_PAYOUTS_SERVICE}/services/${params.service_id}/`);
  },
  techPayoutsNewRegistriesGet: async () => {
    return await api.get(`/${TECH_PAYOUTS_SERVICE}/payouts/registries/active/`);
  },
  techPayoutsNewRegistriesSet: async (params: any) => {
    return await api.post(`/${TECH_PAYOUTS_SERVICE}/payouts/registries/active/`, params);
  },
  techPayoutsNewRegistriesDelete: async () => {
    return await api.delete(`/${TECH_PAYOUTS_SERVICE}/payouts/registries/active/`);
  },
  techPayoutsNewRegistriesValidate: async (formData: FormData) => {
    return await api.post(`/${TECH_PAYOUTS_SERVICE}/payouts/registries/validate`, formData, {
      headers: {
        'Content-Type': `multipart/form-data`, // boundary=${formData._boundary}
      },
    });
  },
  techPayoutNewVerify: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/tech_payout/validate/`, params);
  },
  techPayoutNewServiceInfo: async (id: any) => {
    return await api.get(`/${TECH_PAYOUTS_SERVICE}/payouts/service_info/${id}/`);
  },
};
