import api from '@/api';
import { EXCHANGE_SERVICE } from './constants';

export default {
  exchangeCurrenciesGet: async (params: any) => {
    return await api.get(`/${EXCHANGE_SERVICE}/currencies/`, {
      params,
    });
  },
  exchangeCurrencyGet: async (params: any) => {
    return await api.get(`/${EXCHANGE_SERVICE}/currencies/${params.id}/`);
  },
  exchangeCurrenciesSet: async (params: any) => {
    return await api.post(`/${EXCHANGE_SERVICE}/currencies/`, params);
  },
  exchangeCurrenciesUpdate: async ({ id, ...params }: any) => {
    return await api.patch(`/${EXCHANGE_SERVICE}/currencies/${id}/`, params);
  },
  exchangeCurrenciesDelete: async ({ id }: any) => {
    return await api.delete(`/${EXCHANGE_SERVICE}/currencies/${id}/`);
  },
  exchangeCurrenciesSearch: async (params: any) => {
    return await api.get(`/${EXCHANGE_SERVICE}/currencies/`, {
      params,
    });
  },
};
