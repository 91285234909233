import api from '@/api';
import { AML_SERVICE } from './constants';

export default {
  amlRulesGet: async (params: any) => {
    return await api.get(`/${AML_SERVICE}/rules/`, {
      params,
    });
  },
  amlRulesSearch: async (params: any) => {
    return await api.get(`/${AML_SERVICE}/rules/search/`, {
      params,
    });
  },
  amlRulesGetOne: async ({ id }: any) => {
    return await api.get(`/${AML_SERVICE}/rules/${id}/`);
  },
  amlRulesSet: async (params: any) => {
    return await api.post(`/${AML_SERVICE}/rules/`, params);
  },
  amlRulesUpdate: async ({ id, ...params }: any) => {
    return await api.patch(`/${AML_SERVICE}/rules/${id}/`, params);
  },
  amlRulesDelete: async (params: Record<string, unknown>) => {
    return await api.delete(`/${AML_SERVICE}/rules/${params.id}/`);
  },
};
