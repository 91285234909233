export default {
  name: '',
  path: 'registries',
  component: () => import('@/views/registries/RegistriesIndex.vue'),
  children: [
    {
      path: '',
      name: 'registries',
      component: () => import('@/views/registries/Registries.vue'),
      props: true,
      meta: {
        module: 'registries',
        breadcrumbs: [{ text: 'routes.registries', exact: true, to: { name: 'registries' }, disabled: true }],
      },
    },
    {
      path: ':id',
      name: 'registries-details',
      component: () => import('@/views/registries/RegistriesDetails.vue'),
      props: true,
      meta: {
        module: 'registries',
        breadcrumbs: [
          { text: 'routes.registries', exact: true, to: { name: 'registries' }, disabled: false },
          { text: 'routes.registries_info' },
        ],
      },
    },
  ],
};
