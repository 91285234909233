export default {
  name: '',
  path: 'exchange-orders',
  component: () => import('@/views/exchangeOrders/ExchangeOrdersIndex.vue'),
  children: [
    {
      path: '',
      name: 'exchange-orders',
      component: () => import('@/views/exchangeOrders/ExchangeOrders.vue'),
      props: true,
      meta: {
        module: 'exchange-orders',
        breadcrumbs: [
          { text: 'routes.exchange_orders', exact: true, to: { name: 'exchange-orders' }, disabled: true },
        ],
      },
    },
    {
      path: ':id',
      name: 'exchange-order',
      component: () => import('@/views/exchangeOrders/ExchangeOrder.vue'),
      props: true,
      meta: {
        module: 'exchange-orders',
        breadcrumbs: [
          { text: 'routes.exchange_orders', exact: true, to: { name: 'exchange-orders' } },
          { text: 'routes.exchange_order', disabled: true },
        ],
      },
    },
  ],
};
