import api from '@/api';
import { CARDS_SERVICE } from './constants';

export default {
  cardsKeysGet: async (params: any) => {
    return await api.get(`/${CARDS_SERVICE}/encryption-keys/`, {
      params,
    });
  },
  cardsKeysSet: async () => {
    return await api.post(`/${CARDS_SERVICE}/encryption-keys/`);
  },
};
