import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  cascadingFilterItemsGet: async ({ filterId, ...params }: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/cascading/filters/${filterId}/items/`, {
      params,
    });
  },
  cascadingFilterItemsSet: async ({ filterId, data }: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/cascading/filters/${filterId}/items/`, data);
  },
  cascadingFilterItemsDelete: async ({ filterId, data }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/cascading/filters/${filterId}/items/`, { data });
  },
};
