export default {
  name: '',
  path: 'exchange-widgets',
  component: () => import('@/views/exchangeWidgets/ExchangeWidgetsIndex.vue'),
  children: [
    {
      path: '',
      name: 'exchange-widgets',
      component: () => import('@/views/exchangeWidgets/ExchangeWidgets.vue'),
      props: true,
      meta: {
        module: 'exchange-widgets',
        breadcrumbs: [
          { text: 'routes.exchange_widgets', exact: true, to: { name: 'exchange-widgets' }, disabled: true },
        ],
      },
    },
    {
      name: 'exchangeWidgetsCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/exchangeWidgets/ExchangeWidgetsCreate.vue'),
      meta: {
        module: 'exchange-widgets',
        breadcrumbs: [
          { text: 'routes.exchange_widgets', exact: true, to: { name: 'exchange-widgets' }, disabled: false },
          { text: 'routes.exchange_widgets_create' },
        ],
      },
    },
    {
      name: 'exchangeWidgetsUpdate',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/exchangeWidgets/ExchangeWidgetsUpdate.vue'),
      meta: {
        module: 'exchange-widgets',
        breadcrumbs: [
          { text: 'routes.exchange_widgets', exact: true, to: { name: 'exchange-widgets' }, disabled: false },
          { text: 'routes.exchange_widgets_edit' },
        ],
      },
    },
  ],
};
