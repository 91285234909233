export default {
  name: '',
  path: 'commissions-crypto',
  component: () => import('@/views/commissionsCrypto/CommissionsCryptoIndex.vue'),
  children: [
    {
      path: '',
      name: 'commissions-crypto',
      component: () => import('@/views/commissionsCrypto/CommissionsCrypto.vue'),
      props: true,
      meta: {
        module: 'commissions',
        breadcrumbs: [
          { text: 'routes.commissions_crypto', exact: true, to: { name: 'commissions-crypto' }, disabled: true },
        ],
      },
    },
    {
      name: 'commissionsCryptoCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/commissionsCrypto/CommissionsCryptoCreate.vue'),
      meta: {
        module: 'commissions',
        breadcrumbs: [
          { text: 'routes.commissions_crypto', exact: true, to: { name: 'commissions-crypto' }, disabled: false },
          { text: 'routes.commissions_crypto_create' },
        ],
      },
    },
    {
      name: 'commissionsCryptoUpdate',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/commissionsCrypto/CommissionsCryptoUpdate.vue'),
      meta: {
        module: 'commissions',
        breadcrumbs: [
          { text: 'routes.commissions_crypto', exact: true, to: { name: 'commissions-crypto' }, disabled: false },
          { text: 'routes.commissions_crypto_update' },
        ],
      },
    },
  ],
};
