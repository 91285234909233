export default {
  name: '',
  path: '/users',
  component: () => import('@/views/users/UsersIndex.vue'),
  children: [
    {
      path: '',
      name: 'users',
      component: () => import('@/views/users/Users.vue'),
      props: true,
      meta: {
        module: 'users',
        breadcrumbs: [{ text: 'routes.users', exact: true, to: { name: 'users' }, disabled: true }],
      },
    },
    {
      name: 'usersCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/users/UsersCreate.vue'),
      meta: {
        module: 'users',
        breadcrumbs: [
          { text: 'routes.users', exact: true, to: { name: 'users' }, disabled: false },
          { text: 'routes.user_create' },
        ],
      },
    },
    {
      name: 'usersUpdate',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/users/UsersUpdate.vue'),
      meta: {
        module: 'users',
        breadcrumbs: [
          { text: 'routes.users', exact: true, to: { name: 'users' }, disabled: false },
          { text: 'routes.user_edit' },
        ],
      },
    },
  ],
};
