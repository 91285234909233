import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  walletCommissionsGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/wallets/commissions/`, {
      params,
    });
  },
  walletCommissionsSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/wallets/commissions/create/`, params);
  },
  walletCommissionsUpdate: async ({ id, ...params }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/wallets/commissions/update/${id}/`, params);
  },
  walletCommissionDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/wallets/commissions/${id}/`);
  },
};
