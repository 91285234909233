export default {
  name: '',
  path: 'gateways',
  component: () => import('@/views/gateways/GatewaysIndex.vue'),
  children: [
    {
      path: '',
      name: 'gateways',
      component: () => import('@/views/gateways/Gateways.vue'),
      props: true,
      meta: {
        module: 'gateways',
        breadcrumbs: [{ text: 'routes.geteways', exact: true, to: { name: 'gateways' }, disabled: true }],
      },
    },
    {
      name: 'gatewaysCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/gateways/GatewaysCreate.vue'),
      meta: {
        module: 'gateways',
        breadcrumbs: [
          { text: 'routes.geteways', exact: true, to: { name: 'gateways' }, disabled: false },
          { text: 'routes.gateway_create' },
        ],
      },
    },
    {
      name: 'gatewaysUpdate',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/gateways/GatewaysUpdate.vue'),
      meta: {
        module: 'gateways',
        breadcrumbs: [
          { text: 'routes.geteways', exact: true, to: { name: 'gateways' }, disabled: false },
          { text: 'routes.gateway_edit' },
        ],
      },
    },
  ],
};
