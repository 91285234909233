import api from '@/api';
import { REPORTS_REGISTRIES_SERVICE } from './constants';

export default {
  reportEmailsGet: async (params: Record<string, unknown>) => {
    return await api.get(`/${REPORTS_REGISTRIES_SERVICE}/emails/`, { params });
  },
  reportEmailsSearch: async (params: Record<string, unknown>) => {
    return await api.get(`/${REPORTS_REGISTRIES_SERVICE}/emails/`, { params });
  },
  reportEmailGet: async (params: Record<string, unknown>) => {
    return await api.get(`/${REPORTS_REGISTRIES_SERVICE}/emails/`, { params });
  },
  reportEmailsSet: async (payload: any) => {
    return await api.post(`/${REPORTS_REGISTRIES_SERVICE}/emails/`, payload);
  },
  reportEmailsUpdate: async ({ id, ...payload }: any) => {
    return await api.patch(`/${REPORTS_REGISTRIES_SERVICE}/emails/${id}/`, payload);
  },
  reportEmailsDelete: async (params: any) => {
    return await api.delete(`/${REPORTS_REGISTRIES_SERVICE}/emails/${params.id}/`);
  },
};
