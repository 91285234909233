import api from '@/api';
import { EXCHANGE_SERVICE } from './constants';

export default {
  exchangeRatesGet: async (params: any) => {
    return await api.get(`/${EXCHANGE_SERVICE}/rates/`, {
      params,
    });
  },
  exchangeRateGet: async (params: any) => {
    return await api.get(`/${EXCHANGE_SERVICE}/rates/${params.id}/`);
  },
  exchangeRatesSet: async (params: any) => {
    return await api.post(`/${EXCHANGE_SERVICE}/rates/`, params);
  },
  exchangeRatesUpdate: async ({ id, ...params }: any) => {
    return await api.patch(`/${EXCHANGE_SERVICE}/rates/${id}/`, params);
  },
  exchangeRatesDelete: async ({ id }: any) => {
    return await api.delete(`/${EXCHANGE_SERVICE}/rates/${id}/`);
  },
  exchangeRatesSearch: async (params: any) => {
    return await api.get(`/${EXCHANGE_SERVICE}/rates/`, {
      params,
    });
  },
};
