export default {
  name: '',
  path: 'wallets',
  component: () => import('@/views/wallets/WalletsIndex.vue'),
  children: [
    {
      path: '',
      name: 'wallets',
      component: () => import('@/views/wallets/Wallets.vue'),
      props: true,
      meta: {
        module: 'wallets',
        breadcrumbs: [{ text: 'routes.wallets', exact: true, to: { name: 'wallets' }, disabled: true }],
      },
    },
    {
      name: 'walletsCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/wallets/WalletsCreate.vue'),
      meta: {
        module: 'wallets',
        breadcrumbs: [
          { text: 'routes.wallets', exact: true, to: { name: 'wallets' }, disabled: false },
          { text: 'routes.wallet_create' },
        ],
      },
    },
    {
      name: 'walletsUpdate',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/wallets/WalletsUpdate.vue'),
      meta: {
        module: 'wallets',
        breadcrumbs: [
          { text: 'routes.wallets', exact: true, to: { name: 'wallets' }, disabled: false },
          { text: 'routes.wallet_edit' },
        ],
      },
    },
    {
      name: 'walletHistory',
      path: 'history/:id',
      props: true,
      component: () => import('@/views/wallets/WalletsHistory.vue'),
      meta: {
        module: 'wallets',
        breadcrumbs: [
          { text: 'routes.wallets', exact: true, to: { name: 'wallets' }, disabled: false },
          { text: 'routes.wallet_history' },
        ],
      },
    },
  ],
};
