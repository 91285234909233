import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  merchantCommissionGet: async ({ id, params }: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/merchants/commissions/${id}/`, {
      params,
    });
  },
  merchantCommissionUpdate: async ({ id, ...params }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/merchants/commissions/${id}/`, params);
  },
  merchantCommissionDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/merchants/commissions/${id}/`);
  },
  merchantCommissionsGet: async ({ merchant_id, ...params }: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/merchants/${merchant_id}/commissions/`, {
      params,
    });
  },
  merchantCommissionsSet: async ({ merchant_id, ...params }: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/merchants/${merchant_id}/commissions/`, params);
  },
};
