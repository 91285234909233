export default {
  name: '',
  path: 'providers',
  component: () => import('@/views/providers/ProvidersIndex.vue'),
  children: [
    {
      path: '',
      name: 'providers',
      component: () => import('@/views/providers/Providers.vue'),
      props: true,
      meta: {
        module: 'providers',
        breadcrumbs: [{ text: 'routes.providers', exact: true, to: { name: 'providers' }, disabled: true }],
      },
    },
    {
      name: 'providersCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/providers/ProvidersCreate.vue'),
      meta: {
        module: 'providers',
        breadcrumbs: [
          { text: 'routes.providers', exact: true, to: { name: 'providers' }, disabled: false },
          { text: 'routes.provider_create' },
        ],
      },
    },
    {
      name: 'providersUpdate',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/providers/ProvidersUpdate.vue'),
      meta: {
        module: 'providers',
        breadcrumbs: [
          { text: 'routes.providers', exact: true, to: { name: 'providers' }, disabled: false },
          { text: 'routes.provider_edit' },
        ],
      },
    },
  ],
};
