export default {
  name: '',
  path: 'new-tech-payouts',
  component: () => import('@/views/techPayoutsNew/PayoutsIndex.vue'),
  children: [
    {
      path: '',
      name: 'new-tech-payouts',
      component: () => import('@/views/techPayoutsNew/Payouts.vue'),
      props: true,
      meta: {
        module: 'newTechPayouts',
        breadcrumbs: [{ text: 'routes.tech_payouts', exact: true, to: { name: 'new-tech-payouts' }, disabled: true }],
      },
    },
    {
      name: 'new-techPayoutsCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/techPayoutsNew/PayoutsCreate.vue'),
      meta: {
        module: 'newTechPayouts',
        breadcrumbs: [
          { text: 'routes.tech_payouts', exact: true, to: { name: 'new-tech-payouts' }, disabled: false },
          { text: 'routes.tech_payout_create' },
        ],
      },
    },
  ],
};
