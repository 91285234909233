/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import 'vuetify/styles';
import colors from 'vuetify/lib/util/colors.mjs';
import { VDataTable, VDataTableServer } from 'vuetify/labs/VDataTable';
import { VStepper, VStepperActions, VStepperHeader, VStepperItem } from 'vuetify/labs/VStepper';
import { VInfiniteScroll } from 'vuetify/labs/VInfiniteScroll';

// Composables
import { createVuetify } from 'vuetify';

const themes = {
  default: {
    dark: false,
    colors: {
      primary: colors.blueGrey.base,
      secondary: colors.blueGrey.lighten5,
    },
  },
  custom: {
    dark: false,
    colors: {
      primary: '#3f51b5',
      secondary: '#193a9a',
      // accent: '#e91e63',
      error: '#ff5252',
      info: '#2196f3',
      success: '#4caf50',
      warning: '#ffc107',
    },
  },
};

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default () => {
  const defaultTheme = window.__app_theme in themes ? window.__app_theme : 'default';

  return createVuetify({
    components: { VDataTable, VDataTableServer, VStepper, VStepperActions, VStepperHeader, VStepperItem, VInfiniteScroll },
    defaults: {
      VTextField: {
        variant: 'underlined',
        color: 'primary',
      },
      VTextarea: {
        variant: 'underlined',
        color: 'primary',
      },
      VCheckbox: {
        color: 'primary',
      },
      VSwitch: {
        color: 'primary',
      },
      VAutocomplete: {
        color: 'primary',
      },
    },
    theme: {
      defaultTheme,
      themes,
    },
  });
};
