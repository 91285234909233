import api from '@/api';
import { EXCHANGE_OFFICE } from './constants';

export default {
  exchangeWidgetsGet: async (params: any) => {
    return await api.get(`/${EXCHANGE_OFFICE}/widgets/`, {
      params,
    });
  },
  exchangeWidgetGet: async (params: any) => {
    return await api.get(`/${EXCHANGE_OFFICE}/widgets/${params.id}/`);
  },
  exchangeWidgetsSet: async (params: any) => {
    return await api.post(`/${EXCHANGE_OFFICE}/widgets/`, params);
  },
  exchangeWidgetsUpdate: async ({ id, ...params }: any) => {
    return await api.patch(`/${EXCHANGE_OFFICE}/widgets/${id}/`, params);
  },
  exchangeWidgetsDelete: async ({ id }: any) => {
    return await api.delete(`/${EXCHANGE_OFFICE}/widgets/${id}/`);
  },
  exchangeWidgetsSearch: async (params: any) => {
    return await api.get(`/${EXCHANGE_OFFICE}/widgets/`, {
      params,
    });
  },

  exchangeWidgetsPairsGet: async ({ widgetId, ...params }: any) => {
    return await api.get(`/${EXCHANGE_OFFICE}/widgets/${widgetId}/pairs/`, {
      params,
    });
  },
  exchangeWidgetsPairsSet: async ({ widgetId, ...params }: any) => {
    return await api.post(`/${EXCHANGE_OFFICE}/widgets/${widgetId}/pairs/`, params);
  },
  exchangeWidgetsPairsDelete: async ({ widgetId, id }: any) => {
    return await api.delete(`/${EXCHANGE_OFFICE}/widgets/${widgetId}/pairs/${id}/`);
  },
};
