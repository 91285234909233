/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import VuetifyUseDialog from 'vuetify-use-dialog';
import pinia from '@/store';
import router from '@/router';
import i18n from '@/i18n';
import { loadFonts } from './webfontloader';
import vuetify from './vuetify';
import * as Sentry from '@sentry/vue';
import InputMask from 'inputmask/dist/inputmask.min.js';

import cronCore from '@vue-js-cron/core';

// Types
import type { App } from 'vue';

export function registerPlugins(app: App) {
  loadFonts();

  Sentry.init({
    app,
    dsn: 'https://988b6ed0eeb15191482303851252e50c@o4507644677521408.ingest.de.sentry.io/4507650349924432 ',
    integrations: [Sentry.browserTracingIntegration({ router })],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ['localhost', location.origin],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  app
    .use(vuetify())
    .use(router)
    .use(pinia)
    .use(i18n)
    .use(VuetifyUseDialog)
    .use(cronCore)
    .directive('mask', {
      beforeMount(el, binding: any) {
        if (binding.value?.$nested) {
          el = el.querySelector('input');
        }
        InputMask(binding.value).mask(el);
        if (binding.value && binding.value.autoFocus) {
          el.focus();
        }
      },
    });
}
