<template>
  <v-overlay v-model="$app.isBusy" class="align-center justify-center" persistent>
    <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script lang="ts" setup>
  import { useAppStore } from '@/store/app.store';

  const $app = useAppStore();
</script>
