export default {
  name: '',
  path: 'exchange-scenarios',
  component: () => import('@/views/exchangeScenarios/ExchangeScenariosIndex.vue'),
  children: [
    {
      path: '',
      name: 'exchange-scenarios',
      component: () => import('@/views/exchangeScenarios/ExchangeScenarios.vue'),
      props: true,
      meta: {
        module: 'exchange-scenarios',
        breadcrumbs: [
          { text: 'routes.exchange_scenarios', exact: true, to: { name: 'exchange-scenarios' }, disabled: true },
        ],
      },
    },
    {
      name: 'exchangeScenariosCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/exchangeScenarios/ExchangeScenariosCreate.vue'),
      meta: {
        module: 'exchange-scenarios',
        breadcrumbs: [
          { text: 'routes.exchange_scenarios', exact: true, to: { name: 'exchange-scenarios' }, disabled: false },
          { text: 'routes.exchange_scenarios_create' },
        ],
      },
    },
    {
      name: 'exchangeScenariosUpdate',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/exchangeScenarios/ExchangeScenariosUpdate.vue'),
      meta: {
        module: 'exchange-scenarios',
        breadcrumbs: [
          { text: 'routes.exchange_scenarios', exact: true, to: { name: 'exchange-scenarios' }, disabled: false },
          { text: 'routes.exchange_scenarios_edit' },
        ],
      },
    },
  ],
};
