import api from '@/api';
import { CRYPTO_GATEWAYS_SERVICE } from './constants';

export default {
  autoSwapRulesGet: async ({ providerId, ...params }: any) => {
    return await api.get(`${CRYPTO_GATEWAYS_SERVICE}/providers/${providerId}/auto_swap_rules/`, { params });
  },
  autoSwapRuleGet: async ({ providerId, id }: any) => {
    return await api.get(`${CRYPTO_GATEWAYS_SERVICE}/providers/${providerId}/auto_swap_rules/${id}/`);
  },
  autoSwapRuleSet: async ({ providerId, ...params }: any) => {
    return await api.post(`${CRYPTO_GATEWAYS_SERVICE}/providers/${providerId}/auto_swap_rules/`, params);
  },
  autoSwapRuleUpdate: async ({ providerId, id, ...params }: any) => {
    return await api.patch(`${CRYPTO_GATEWAYS_SERVICE}/providers/${providerId}/auto_swap_rules/${id}/`, params);
  },
  autoSwapRuleDelete: async ({ providerId, id }: any) => {
    return await api.delete(`${CRYPTO_GATEWAYS_SERVICE}/providers/${providerId}/auto_swap_rules/${id}/`);
  },
  autoSwapRulesSwapsGet: async ({ providerId, id, ...params }: any) => {
    return await api.get(`${CRYPTO_GATEWAYS_SERVICE}/providers/${providerId}/auto_swap_rules/${id}/swaps/`, {
      params,
    });
  },
};
