import api from '@/api';
import { AML_SERVICE } from './constants';

export default {
  amlChecksGet: async (params: any) => {
    return await api.get(`/${AML_SERVICE}/checks/`, {
      params,
    });
  },
  amlChecksSearch: async (params: any) => {
    return await api.get(`/${AML_SERVICE}/checks/`, {
      params,
    });
  },
  amlChecksGetOne: async ({ id }: any) => {
    return await api.get(`/${AML_SERVICE}/checks/${id}/`);
  },
};
