import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  servicesGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/services/`, {
      params,
    });
  },
  servicesSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/services/create/`, params);
  },
  servicesUpdate: async ({ id, ...params }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/services/update/${id}/`, params);
  },
  servicesDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/services/${id}/`);
  },
  servicesSearch: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/services/search/`, {
      params,
    });
  },
  servicesFieldsGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/services/fields/`, {
      params,
    });
  },
  servicesFieldGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/services/fields/`, {
      params,
    });
  },
  servicesFieldsSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/services/fields/create/`, params);
  },
  servicesFieldsUpdate: async ({ id, ...params }: any) => {
    // const paramModified = Object.assign({}, params);
    delete params.service_id;
    return await api.put(`/${DEFAULT_SERVICE}/services/fields/update/${id}/`, params);
  },
  servicesFieldsDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/services/fields/${id}/`);
  },
  serviceCopy: async ({ id }: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/services/${id}/copy/`);
  },
};
