export default {
  name: '',
  path: 'cascading-templates',
  component: () => import('@/views/cascadingTemplates/CascadingIndex.vue'),
  children: [
    {
      path: '',
      name: 'cascadingTemplates',
      component: () => import('@/views/cascadingTemplates/CascadingTemplates.vue'),
      props: true,
      meta: {
        module: 'cascading',
        breadcrumbs: [{ text: 'routes.templates', exact: true, to: { name: 'cascadingTemplates' }, disabled: true }],
      },
    },
    {
      path: 'create',
      name: 'cascadingTemplatesCreate',
      component: () => import('@/views/cascadingTemplates/CascadingTemplatesCreate.vue'),
      props: true,
      meta: {
        module: 'cascading',
        breadcrumbs: [
          { text: 'routes.templates', exact: true, to: { name: 'cascadingTemplates' }, disabled: false },
          { text: 'routes.template_create' },
        ],
      },
    },
    {
      path: 'update/:id',
      name: 'cascadingTemplatesUpdate',
      component: () => import('@/views/cascadingTemplates/CascadingTemplatesUpdate.vue'),
      props: true,
      meta: {
        module: 'cascading',
        breadcrumbs: [
          { text: 'routes.templates', exact: true, to: { name: 'cascadingTemplates' }, disabled: false },
          { text: 'routes.template_edit' },
        ],
      },
    },

    {
      path: ':template_id/gateways/create',
      name: 'cascadingGatewaysCreate',
      component: () => import('@/views/cascadingTemplates/CascadingGatewaysCreate.vue'),
      props: true,
      meta: {
        module: 'cascading',
        breadcrumbs: [
          { text: 'routes.templates', exact: true, to: { name: 'cascadingTemplates' }, disabled: false },
          { text: 'routes.template_edit' },
          { text: 'routes.gateway_create' },
        ],
      },
    },
    {
      path: ':template_id/gateways/update/:id',
      name: 'cascadingGatewaysUpdate',
      component: () => import('@/views/cascadingTemplates/CascadingGatewaysUpdate.vue'),
      props: true,
      meta: {
        module: 'cascading',
        breadcrumbs: [
          { text: 'routes.templates', exact: true, to: { name: 'cascadingTemplates' }, disabled: false },
          { text: 'routes.template_edit' },
          { text: 'routes.gateway_edit' },
        ],
      },
    },
  ],
};
