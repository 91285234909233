export default {
  name: '',
  path: 'reports-extra',
  component: () => import('@/views/reportsExtra/ReportsExtraIndex.vue'),
  children: [
    {
      path: '',
      name: 'reports-extra',
      component: () => import('@/views/reportsExtra/ReportsExtra.vue'),
      props: true,
      meta: {
        // module: 'reports',
        breadcrumbs: [{ text: 'routes.report_extra', exact: true, to: { name: 'reports-extra' }, disabled: true }],
      },
    },
  ],
};
