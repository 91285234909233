export default {
  name: '',
  path: 'reports',
  component: () => import('@/views/reports/ReportsIndex.vue'),
  children: [
    {
      path: '',
      name: 'reports',
      component: () => import('@/views/reports/Reports.vue'),
      props: true,
      meta: {
        // module: 'reports',
        breadcrumbs: [{ text: 'routes.reports', exact: true, to: { name: 'reports' }, disabled: true }],
      },
    },
    {
      path: 'create',
      name: 'reportsCreate',
      component: () => import('@/views/reports/ReportsCreate.vue'),
      props: true,
      meta: {
        // module: 'reports',
        breadcrumbs: [
          { text: 'routes.reports', exact: true, to: { name: 'reports' } },
          { text: 'routes.reports_create' },
        ],
      },
    },
    {
      path: 'update/:id',
      name: 'reportsUpdate',
      component: () => import('@/views/reports/ReportsUpdate.vue'),
      props: true,
      meta: {
        // module: 'reports',
        breadcrumbs: [
          { text: 'routes.reports', exact: true, to: { name: 'reports' } },
          { text: 'routes.reports_update' },
        ],
      },
    },
  ],
};
