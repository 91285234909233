import api from '@/api';
import { EXCHANGE_OFFICE } from './constants';

export default {
  exchangeBalanceSyncTasksGet: async (params: any) => {
    return await api.get(`/${EXCHANGE_OFFICE}/balance_sync_tasks/`, {
      params,
    });
  },
  exchangeBalanceSyncTaskGet: async (params: any) => {
    return await api.get(`/${EXCHANGE_OFFICE}/balance_sync_tasks/${params.id}/`);
  },
  exchangeBalanceSyncTasksSet: async (params: any) => {
    return await api.post(`/${EXCHANGE_OFFICE}/balance_sync_tasks/`, params);
  },
  exchangeBalanceSyncTasksUpdate: async ({ id, ...params }: any) => {
    return await api.patch(`/${EXCHANGE_OFFICE}/balance_sync_tasks/${id}/`, params);
  },
  exchangeBalanceSyncTasksDelete: async ({ id }: any) => {
    return await api.delete(`/${EXCHANGE_OFFICE}/balance_sync_tasks/${id}/`);
  },

  exchangeBalanceSyncTasksHistoryGet: async (params: any) => {
    return await api.get(`/${EXCHANGE_OFFICE}/balance_sync_tasks_history/`, {
      params,
    });
  },

  exchangeBalanceSyncTasksForceStart: async ({ id, ...params }: any) => {
    return await api.post(`/${EXCHANGE_OFFICE}/balance_sync_tasks/${id}/force_start/`, params);
  },
};
