export default {
  name: '',
  path: 'aml-protocols',
  component: () => import('@/views/amlProtocols/AmlProtocolsIndex.vue'),
  children: [
    {
      path: '',
      name: 'aml-protocols',
      component: () => import('@/views/amlProtocols/AmlProtocols.vue'),
      props: true,
      meta: {
        breadcrumbs: [{ text: 'routes.aml_protocols', exact: true, to: { name: 'aml-protocols' }, disabled: true }],
      },
    },
    {
      path: 'create',
      name: 'aml-protocols-create',
      component: () => import('@/views/amlProtocols/AmlProtocolsCreate.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          { text: 'routes.aml_protocols', exact: true, to: { name: 'aml-protocols' } },
          { text: 'routes.aml_protocols_create' },
        ],
      },
    },
    {
      path: 'update/:id',
      name: 'aml-protocols-update',
      component: () => import('@/views/amlProtocols/AmlProtocolsUpdate.vue'),
      props: true,
      meta: {
        // module: 'reports',
        breadcrumbs: [
          { text: 'routes.aml_protocols', exact: true, to: { name: 'aml-protocols' } },
          { text: 'routes.aml_protocols_update' },
        ],
      },
    },
  ],
};
