export default {
  name: '',
  path: 'system-emails',
  component: () => import('@/views/systemEmails/SystemEmailsIndex.vue'),
  children: [
    {
      path: '',
      name: 'system-emails',
      component: () => import('@/views/systemEmails/SystemEmails.vue'),
      props: true,
      meta: {
        breadcrumbs: [{ text: 'routes.system_emails', exact: true, to: { name: 'system-emails' }, disabled: true }],
      },
    },
    {
      path: 'create',
      name: 'system-emails-create',
      component: () => import('@/views/systemEmails/SystemEmailsCreate.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          { text: 'routes.system_emails', exact: true, to: { name: 'system-emails' } },
          { text: 'routes.system_emails_create' },
        ],
      },
    },
    {
      path: 'update/:id',
      name: 'system-emails-update',
      component: () => import('@/views/systemEmails/SystemEmailsUpdate.vue'),
      props: true,
      meta: {
        // module: 'reports',
        breadcrumbs: [
          { text: 'routes.system_emails', exact: true, to: { name: 'system-emails' } },
          { text: 'routes.system_emails_edit' },
        ],
      },
    },
  ],
};
