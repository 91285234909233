import api from '@/api';
import { REPORTS_REGISTRIES_SERVICE } from './constants';

export default {
  reportRegistriesGet: async (params: any) => {
    return await api.get(`/${REPORTS_REGISTRIES_SERVICE}/registries/`, {
      params,
    });
  },
  reportRegistryGet: async ({ id }: any) => {
    return await api.get(`/${REPORTS_REGISTRIES_SERVICE}/registries/${id}/`);
  },
  reportRegistriesSet: async (params: any) => {
    return await api.post(`/${REPORTS_REGISTRIES_SERVICE}/registries/`, params);
  },
  reportRegistriesUpdate: async ({ id, ...params }: any) => {
    return await api.patch(`/${REPORTS_REGISTRIES_SERVICE}/registries/${id}/`, params);
  },
  reportRegistriesDelete: async (params: Record<string, unknown>) => {
    return await api.delete(`/${REPORTS_REGISTRIES_SERVICE}/registries/${params.id}/`);
  },

  reportRegistriesEmailsGet: async (params: Record<string, unknown>) => {
    return await api.get(`/${REPORTS_REGISTRIES_SERVICE}/emails-registries/`, { params });
  },
  reportRegistriesEmailsSearch: async (params: Record<string, unknown>) => {
    return await api.get(`/${REPORTS_REGISTRIES_SERVICE}/emails-registries/`, { params });
  },
  reportRegistriesEmailsSet: async (payload: any) => {
    return await api.post(`/${REPORTS_REGISTRIES_SERVICE}/emails-registries/`, payload);
  },
  reportRegistriesEmailsDelete: async (params: any) => {
    return await api.delete(`/${REPORTS_REGISTRIES_SERVICE}/emails-registries/${params.id}/`);
  },

  reportRegistriesTelegramsGet: async (params: Record<string, unknown>) => {
    return await api.get(`/${REPORTS_REGISTRIES_SERVICE}/telegram-chats-registries/`, { params });
  },
  reportRegistriesTelegramsSearch: async (params: Record<string, unknown>) => {
    return await api.get(`/${REPORTS_REGISTRIES_SERVICE}/telegram-chats-registries/`, { params });
  },
  reportRegistriesTelegramsSet: async (payload: any) => {
    return await api.post(`/${REPORTS_REGISTRIES_SERVICE}/telegram-chats-registries/`, payload);
  },
  reportRegistriesTelegramsDelete: async (params: any) => {
    return await api.delete(`/${REPORTS_REGISTRIES_SERVICE}/telegram-chats-registries/${params.id}/`);
  },
};
