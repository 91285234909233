export default {
  name: '',
  path: 'new-registries',
  component: () => import('@/views/registriesNew/RegistriesIndex.vue'),
  children: [
    {
      path: '',
      name: 'new-registries',
      component: () => import('@/views/registriesNew/Registries.vue'),
      props: true,
      meta: {
        module: 'new-registries',
        breadcrumbs: [{ text: 'routes.registries', exact: true, to: { name: 'new-registries' }, disabled: true }],
      },
    },
    {
      path: ':id',
      name: 'new-registries-details',
      component: () => import('@/views/registriesNew/RegistriesDetails.vue'),
      props: true,
      meta: {
        module: 'new-registries',
        breadcrumbs: [
          { text: 'routes.registries', exact: true, to: { name: 'new-registries' }, disabled: false },
          { text: 'routes.registries_info' },
        ],
      },
    },
    {
      name: 'new-techPayoutsRegistriesCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/registriesNew/RegistriesCreate.vue'),
      meta: {
        module: 'newTechPayouts',
        breadcrumbs: [
          { text: 'routes.tech_payouts', exact: true, to: { name: 'new-registries' }, disabled: false },
          { text: 'routes.tech_payout_create' },
        ],
      },
    },
  ],
};
