import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  domainNamesGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/merchants/domain_names/`, {
      params,
    });
  },
  domainNameGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/merchants/domain_names/${params.id}`);
  },
  domainNamesSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/merchants/domain_names/`, params);
  },
  domainNamesUpdate: async ({ id, ...params }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/merchants/domain_names/${id}/`, params);
  },
  domainNamesDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/merchants/domain_names/${id}/`);
  },
  domainNamesSearch: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/merchants/domain_names/search/`, {
      params,
    });
  },
};
