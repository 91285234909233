import api from '@/api';
import { REPORTS_REGISTRIES_SERVICE } from './constants';

export default {
  reportsGet: async (params: any) => {
    return await api.get(`/${REPORTS_REGISTRIES_SERVICE}/reports/`, {
      params,
    });
  },
  reportsSearch: async (params: any) => {
    return await api.get(`/${REPORTS_REGISTRIES_SERVICE}/reports/`, {
      params,
    });
  },
  reportGet: async ({ id }: any) => {
    return await api.get(`/${REPORTS_REGISTRIES_SERVICE}/reports/${id}/`);
  },
  reportsSet: async (params: any) => {
    return await api.post(`/${REPORTS_REGISTRIES_SERVICE}/reports/`, params);
  },
  reportsUpdate: async ({ id, ...params }: any) => {
    return await api.patch(`/${REPORTS_REGISTRIES_SERVICE}/reports/${id}/`, params);
  },
  reportsDelete: async (params: Record<string, unknown>) => {
    return await api.delete(`/${REPORTS_REGISTRIES_SERVICE}/reports/${params.id}/`);
  },
  reportsManual: async (params: Record<string, unknown>, target?: string | null) => {
    return await api.post(`/${REPORTS_REGISTRIES_SERVICE}/reports/manual/`, params, {
      params: { target },
      ...(target ? {} : { responseType: 'blob', timeout: 10000000 }),
    });
  },
};
