import api from '@/api';
import { COMMISSIONS_SERVICE } from './constants';

export default {
  commissionsFiatGet: async (params: any) => {
    return await api.get(`/${COMMISSIONS_SERVICE}/commissions/fiat/`, {
      params,
    });
  },
  commissionsFiatGetOne: async ({ id }: any) => {
    return await api.get(`/${COMMISSIONS_SERVICE}/commissions/fiat/${id}/`);
  },
  commissionsFiatSet: async (params: any) => {
    return await api.post(`/${COMMISSIONS_SERVICE}/commissions/fiat/`, params);
  },
  commissionsFiatUpdate: async ({ id, ...params }: any) => {
    return await api.patch(`/${COMMISSIONS_SERVICE}/commissions/fiat/${id}/`, params);
  },
  commissionsFiatDelete: async ({ id }: any) => {
    return await api.delete(`/${COMMISSIONS_SERVICE}/commissions/fiat/${id}/`);
  },
};
