// exchange-balance-sync

export default {
  name: '',
  path: 'exchange-balance-sync',
  component: () => import('@/views/exchangeBalanceSync/ExchangeBalanceSyncIndex.vue'),
  children: [
    {
      path: '',
      name: 'exchange-balance-sync',
      component: () => import('@/views/exchangeBalanceSync/ExchangeBalanceSync.vue'),
      props: true,
      meta: {
        module: 'exchange-balance-sync',
        breadcrumbs: [
          { text: 'routes.exchange_balance_sync', exact: true, to: { name: 'exchange-balance-sync' }, disabled: true },
        ],
      },
    },
    {
      name: 'ExchangeBalanceSyncCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/exchangeBalanceSync/ExchangeBalanceSyncCreate.vue'),
      meta: {
        module: 'exchange-balance-sync',
        breadcrumbs: [
          { text: 'routes.exchange_balance_sync', exact: true, to: { name: 'exchange-balance-sync' }, disabled: false },
          { text: 'routes.exchange_balance_sync_create' },
        ],
      },
    },
    {
      name: 'ExchangeBalanceSyncUpdate',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/exchangeBalanceSync/ExchangeBalanceSyncUpdate.vue'),
      meta: {
        module: 'exchange-balance-sync',
        breadcrumbs: [
          { text: 'routes.exchange_balance_sync', exact: true, to: { name: 'exchange-balance-sync' }, disabled: false },
          { text: 'routes.exchange_balance_sync_edit' },
        ],
      },
    },
  ],
};
