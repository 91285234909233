import api from '@/api';
import { ResultResponse, UserModel } from '@/types';
import { DEFAULT_SERVICE } from './constants';

export interface LoginRequest {
  login: string;
  password: string;
}
export interface LoginResponse {
  id: number | string;
  last_login_at: string;
  result: string; // ? todo, 2fa
}

export interface Login2FaRequest extends LoginRequest {
  otp_key: string;
}

export interface ChangePasswordRequest {
  id: number | string;
  new_password: string;
  new_password_confirm: string;
  old_password: string;
}

export interface Change2FaRequest {
  enable_2fa: boolean;
  enable_payout_2fa: boolean;
  id: number | string;
  password: string;
}

export default {
  login: async (params: LoginRequest) => {
    return await api.post<ResultResponse<UserModel | LoginResponse>>(`/${DEFAULT_SERVICE}/auth/login/`, params);
  },
  login2fa: async (params: Login2FaRequest) => {
    return await api.post<ResultResponse<UserModel>>(`/${DEFAULT_SERVICE}/auth/otp/`, params);
  },
  changePassword: async (params: ChangePasswordRequest) => {
    return await api.post<ResultResponse<boolean>>(`/${DEFAULT_SERVICE}/users/change_password/`, params);
  },
  change2Fa: async (params: Change2FaRequest) => {
    return await api.post<ResultResponse<{ qr: string }>>(`/${DEFAULT_SERVICE}/users/change_2fa/`, params);
  },
};
