export default {
  name: '',
  path: 'merchants',
  component: () => import('@/views/merchants/MerchantsIndex.vue'),
  children: [
    {
      path: '',
      name: 'merchants',
      component: () => import('@/views/merchants/Merchants.vue'),
      props: true,
      meta: {
        module: 'merchants',
        breadcrumbs: [{ text: 'routes.merchants', exact: true, to: { name: 'merchants' }, disabled: true }],
      },
    },
    {
      name: 'merchantsCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/merchants/MerchantsCreate.vue'),
      meta: {
        module: 'merchants',
        breadcrumbs: [
          { text: 'routes.merchants', exact: true, to: { name: 'merchants' }, disabled: false },
          { text: 'routes.merchant_create' },
        ],
      },
    },
    {
      name: 'merchantsUpdate',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/merchants/MerchantsUpdate.vue'),
      meta: {
        module: 'merchants',
        breadcrumbs: [
          { text: 'routes.merchants', exact: true, to: { name: 'merchants' }, disabled: false },
          { text: 'routes.merchant_edit' },
        ],
      },
    },
  ],
  meta: {
    module: 'merchants',
  },
};
