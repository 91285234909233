export default {
  name: '',
  path: 'system-endpoints',
  component: () => import('@/views/system/SystemEndpointsIndex.vue'),
  children: [
    {
      path: '',
      name: 'system-endpoints',
      component: () => import('@/views/system/SystemEndpoints.vue'),
      props: true,
      meta: {
        module: 'SystemEndpoints',
        breadcrumbs: [{ text: 'routes.system_endpoints', exact: true, disabled: true }],
      },
    },
  ],
};
