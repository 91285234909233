export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
  },
  {
    path: '/approve',
    name: 'approve',
    props: true,
    component: () => import('@/views/auth/Approve.vue'),
  },
  {
    path: '/change-password',
    name: 'changePassword',
    props: true,
    component: () => import('@/views/auth/ChangePassword.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];
