export default {
  name: '',
  path: 'vouchers',
  component: () => import('@/views/vouchers/VouchersIndex.vue'),
  children: [
    {
      path: '',
      name: 'vouchers',
      component: () => import('@/views/vouchers/Vouchers.vue'),
      props: true,
      meta: {
        breadcrumbs: [{ text: 'routes.vouchers', exact: true, to: { name: 'vouchers' }, disabled: true }],
      },
    },
    {
      path: 'create',
      name: 'vouchers-create',
      component: () => import('@/views/vouchers/VouchersCreate.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          { text: 'routes.vouchers', exact: true, to: { name: 'vouchers' } },
          { text: 'routes.vouchers_create' },
        ],
      },
    },
    {
      path: 'update/:id',
      name: 'vouchers-update',
      component: () => import('@/views/vouchers/VouchersUpdate.vue'),
      props: true,
      meta: {
        // module: 'reports',
        breadcrumbs: [
          { text: 'routes.vouchers', exact: true, to: { name: 'vouchers' } },
          { text: 'routes.vouchers_update' },
        ],
      },
    },
  ],
};
