export default {
  name: '',
  path: 'cards-geo',
  component: () => import('@/views/cardsGeo/CardsGeoIndex.vue'),
  children: [
    {
      path: '',
      name: 'cards-geo',
      component: () => import('@/views/cardsGeo/CardsGeo.vue'),
      props: true,
      meta: {
        breadcrumbs: [{ text: 'routes.cards_geo', exact: true, to: { name: 'cards-geo' }, disabled: true }],
      },
    },
    {
      path: 'create',
      name: 'cards-geo-create',
      component: () => import('@/views/cardsGeo/CardsGeoCreate.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          { text: 'routes.cards_geo', exact: true, to: { name: 'cards-geo' } },
          { text: 'routes.cards_geo_create' },
        ],
      },
    },
    {
      path: 'update/:id',
      name: 'cards-geo-update',
      component: () => import('@/views/cardsGeo/CardsGeoUpdate.vue'),
      props: true,
      meta: {
        // module: 'reports',
        breadcrumbs: [
          { text: 'routes.cards_geo', exact: true, to: { name: 'cards-geo' } },
          { text: 'routes.cards_geo_update' },
        ],
      },
    },
  ],
};
