import api from '@/api';
import { EXCHANGE_OFFICE } from './constants';

export default {
  exchangeScenariosGet: async (params: any) => {
    return await api.get(`/${EXCHANGE_OFFICE}/scenarios/`, {
      params,
    });
  },
  exchangeScenarioGet: async (params: any) => {
    return await api.get(`/${EXCHANGE_OFFICE}/scenarios/${params.id}/`);
  },
  exchangeScenariosSet: async (params: any) => {
    return await api.post(`/${EXCHANGE_OFFICE}/scenarios/`, params);
  },
  exchangeScenariosUpdate: async ({ id, ...params }: any) => {
    return await api.patch(`/${EXCHANGE_OFFICE}/scenarios/${id}/`, params);
  },
  exchangeScenariosDelete: async ({ id }: any) => {
    return await api.delete(`/${EXCHANGE_OFFICE}/scenarios/${id}/`);
  },
  exchangeScenariosSearch: async (params: any) => {
    return await api.get(`/${EXCHANGE_OFFICE}/scenarios/search/`, {
      params,
    });
  },
};
