import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  protocolsGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/providers/protocols/`, {
      params,
    });
  },
  protocolsSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/providers/protocols/create/`, params);
  },
  protocolsUpdate: async ({ id, ...params }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/providers/protocols/update/${id}/`, params);
  },
  protocolsDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/providers/protocols/${id}/`);
  },
  protocolGet: async (id: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/providers/protocols/${id}/`);
  },
  protocolsSearch: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/providers/protocols/search/`, {
      params,
    });
  },
};
