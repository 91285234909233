import api from '@/api';
import { AML_SERVICE } from './constants';

export default {
  amlGatewaysGet: async (params: any) => {
    return await api.get(`/${AML_SERVICE}/gateways/`, {
      params,
    });
  },
  amlGatewaysSearch: async (params: any) => {
    return await api.get(`/${AML_SERVICE}/gateways/search/`, {
      params,
    });
  },
  amlGatewaysGetOne: async ({ id }: any) => {
    return await api.get(`/${AML_SERVICE}/gateways/${id}/`);
  },
  amlGatewaysSet: async (params: any) => {
    return await api.post(`/${AML_SERVICE}/gateways/`, params);
  },
  amlGatewaysUpdate: async ({ id, ...params }: any) => {
    return await api.patch(`/${AML_SERVICE}/gateways/${id}/`, params);
  },
  amlGatewaysDelete: async (params: Record<string, unknown>) => {
    return await api.delete(`/${AML_SERVICE}/gateways/${params.id}/`);
  },
};
