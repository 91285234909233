export default {
  name: '',
  path: 'merchant-groups',
  component: () => import('@/views/merchantGroups/MerchantGroupsIndex.vue'),
  children: [
    {
      path: '',
      name: 'merchant-groups',
      component: () => import('@/views/merchantGroups/MerchantGroups.vue'),
      props: true,
      meta: {
        module: 'merchant_groups',
        breadcrumbs: [{ text: 'routes.merchant_groups', exact: true, to: { name: 'merchant-groups' }, disabled: true }],
      },
    },
    {
      name: 'merchantGroupsCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/merchantGroups/MerchantGroupsCreate.vue'),
      meta: {
        module: 'merchant_groups',
        breadcrumbs: [
          { text: 'routes.merchant_groups', exact: true, to: { name: 'merchant-groups' }, disabled: false },
          { text: 'routes.merchant_groups_create' },
        ],
      },
    },
    {
      name: 'merchantGroupsUpdate',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/merchantGroups/MerchantGroupsUpdate.vue'),
      meta: {
        module: 'merchant_groups',
        breadcrumbs: [
          { text: 'routes.merchant_groups', exact: true, to: { name: 'merchant-groups' }, disabled: false },
          { text: 'routes.merchant_groups_edit' },
        ],
      },
    },
  ],
};
