export default {
  name: '',
  path: 'reports/registries',
  component: () => import('@/views/reportRegistries/ReportRegistriesIndex.vue'),
  children: [
    {
      path: '',
      name: 'reportRegistries',
      component: () => import('@/views/reportRegistries/ReportRegistries.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          { text: 'routes.report_registries', exact: true, to: { name: 'reportRegistries' }, disabled: true },
        ],
      },
    },
    {
      path: 'create',
      name: 'reportRegistriesCreate',
      component: () => import('@/views/reportRegistries/ReportRegistriesCreate.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          { text: 'routes.report_registries', exact: true, to: { name: 'reportRegistries' } },
          { text: 'routes.report_registries_create' },
        ],
      },
    },
    {
      path: 'update/:id',
      name: 'reportRegistriesUpdate',
      component: () => import('@/views/reportRegistries/ReportRegistriesUpdate.vue'),
      props: true,
      meta: {
        // module: 'reports',
        breadcrumbs: [
          { text: 'routes.report_registries', exact: true, to: { name: 'reportRegistries' } },
          { text: 'routes.report_registries_update' },
        ],
      },
    },
  ],
};
