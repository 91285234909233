import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  securityAccessListsGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/security/access_lists/`, {
      params,
    });
  },
  securityAccessListsSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/security/access_lists/create/`, params);
  },
  securityAccessListsUpdate: async ({ id, ...params }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/security/access_lists/update/${id}/`, params);
  },
  securityAccessListsDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/security/access_lists/${id}/`);
  },
  securityAccessListGet: async (id: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/security/access_lists/${id}/`);
  },
  securityAccessListsSearch: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/security/access_lists/search/`, {
      params,
    });
  },

  securityAccessListItemGet: async (params: any) => {
    // params.access_list_id = params.id;
    // delete params.id;
    return await api.get(`/${DEFAULT_SERVICE}/security/access_lists/items/`, {
      params,
    });
  },
  securityAccessListItemSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/security/access_lists/items/create/`, params);
  },
  securityAccessListItemUpdate: async ({ id, ...params }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/security/access_lists/items/update/${id}/`, params);
  },
  securityAccessListItemDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/security/access_lists/items/${id}/`);
  },
  securityAccessListItemGetOne: async (id: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/security/access_lists/items/${id}/`);
  },

  securityAccessListServiceFieldsGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/security/access_lists/service_fields/`, {
      params,
    });
  },
  securityAccessListServiceFieldsSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/security/access_lists/service_fields/create/`, params);
  },
  securityAccessListServiceFieldsDelete: async (params: { id: any }) => {
    return await api.delete(`/${DEFAULT_SERVICE}/security/access_lists/service_fields/${params.id}/`);
  },
  securityAccessListServiceFieldGet: async (id: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/security/access_lists/service_fields/${id}/`);
  },
};
