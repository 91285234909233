import { defineStore } from 'pinia';

interface AppStore {
  isBusy: boolean;
}

export const useAppStore = defineStore('app', {
  state: () =>
    ({
      isBusy: false,
    } as AppStore),

  actions: {
    setBusy(action: boolean) {
      this.isBusy = action;
    },
  },
});
