export default [
  {
    name: '',
    path: 'crypto-gateway-providers',
    component: () => import('@/views/cryptoGatewayProviders/CryptoGatewayProvidersIndex.vue'),
    children: [
      {
        path: '',
        name: 'crypto-gateway-providers',
        component: () => import('@/views/cryptoGatewayProviders/CryptoGatewayProviders.vue'),
        props: true,
        meta: {
          module: 'crypto_gateways',
          breadcrumbs: [
            {
              text: 'routes.crypto_gateway_providers',
              exact: true,
              to: { name: 'crypto-gateway-providers' },
              disabled: true,
            },
          ],
        },
      },
      {
        name: 'crypto-gateway-providers-create',
        path: 'create',
        props: true,
        component: () => import('@/views/cryptoGatewayProviders/CryptoGatewayProvidersCreate.vue'),
        meta: {
          module: 'currencies',
          breadcrumbs: [
            {
              text: 'routes.crypto_gateway_providers',
              exact: true,
              to: { name: 'crypto-gateway-providers' },
              disabled: false,
            },
            { text: 'routes.crypto_gateway_providers_create' },
          ],
        },
      },
      {
        name: 'crypto-gateway-providers-update',
        path: 'update/:id',
        props: true,
        component: () => import('@/views/cryptoGatewayProviders/CryptoGatewayProvidersUpdate.vue'),
        meta: {
          module: 'currencies',
          breadcrumbs: [
            {
              text: 'routes.crypto_gateway_providers',
              exact: true,
              to: { name: 'crypto-gateway-providers' },
              disabled: false,
            },
            { text: 'routes.crypto_gateway_providers_update' },
          ],
        },
      },
    ],
  },
  {
    path: 'crypto-gateway-providers/:providerId/auto-swap/create',
    name: 'auto-swap-create',
    component: () => import('@/views/cryptoGatewayProviders/autoSwap/AutoSwapCreate.vue'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          text: 'routes.auto_swap_create',
          exact: true,
          to: { name: 'auto-swap-create', params: { providerId: 0 } },
          disabled: true,
        },
      ],
    },
  },
  {
    path: 'crypto-gateway-providers/:providerId/auto-swap/update/:id',
    name: 'auto-swap-update',
    component: () => import('@/views/cryptoGatewayProviders/autoSwap/AutoSwapUpdate.vue'),
    props: true,
    meta: {
      breadcrumbs: [
        {
          text: 'routes.auto_swap_update',
          exact: true,
          to: { name: 'auto-swap-update', params: { providerId: 0, id: 0 } },
          disabled: true,
        },
      ],
    },
  },
];
