import api from '@/api';
import { REPORTS_REGISTRIES_SERVICE } from './constants';

export default {
  reportTelegramsGet: async (params: Record<string, unknown>) => {
    return await api.get(`/${REPORTS_REGISTRIES_SERVICE}/telegram-chats/`, { params });
  },
  reportTelegramsSearch: async (params: Record<string, unknown>) => {
    return await api.get(`/${REPORTS_REGISTRIES_SERVICE}/telegram-chats/`, { params });
  },
  reportTelegramGet: async (params: Record<string, unknown>) => {
    return await api.get(`/${REPORTS_REGISTRIES_SERVICE}/telegram-chats/`, { params });
  },
  reportTelegramsSet: async (payload: any) => {
    return await api.post(`/${REPORTS_REGISTRIES_SERVICE}/telegram-chats/`, payload);
  },
  reportTelegramsUpdate: async ({ id, ...payload }: any) => {
    return await api.patch(`/${REPORTS_REGISTRIES_SERVICE}/telegram-chats/${id}/`, payload);
  },
  reportTelegramsDelete: async (params: any) => {
    return await api.delete(`/${REPORTS_REGISTRIES_SERVICE}/telegram-chats/${params.id}/`);
  },
};
