export default {
  name: '',
  path: 'exchange-rate-providers',
  component: () => import('@/views/exchangeRateProviders/ExchangeRateProvidersIndex.vue'),
  children: [
    {
      path: '',
      name: 'exchangeRateProviders',
      component: () => import('@/views/exchangeRateProviders/ExchangeRateProviders.vue'),
      props: true,
      meta: {
        module: 'exchangeRateProviders',
        breadcrumbs: [
          {
            text: 'routes.exchange_rate_providers',
            exact: true,
            to: { name: 'exchangeRateProviders' },
            disabled: true,
          },
        ],
      },
    },
    {
      name: 'exchangeRateProvidersCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/exchangeRateProviders/ExchangeRateProvidersCreate.vue'),
      meta: {
        module: 'exchangeRateProviders',
        breadcrumbs: [
          {
            text: 'routes.exchange_rate_providers',
            exact: true,
            to: { name: 'exchangeRateProviders' },
            disabled: false,
          },
          { text: 'routes.exchange_rate_providers_create' },
        ],
      },
    },
    {
      name: 'exchangeRateProvidersUpdate',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/exchangeRateProviders/ExchangeRateProvidersUpdate.vue'),
      meta: {
        module: 'exchangeRateProviders',
        breadcrumbs: [
          {
            text: 'routes.exchange_rate_providers',
            exact: true,
            to: { name: 'exchangeRateProviders' },
            disabled: false,
          },
          { text: 'routes.exchange_rate_providers_edit' },
        ],
      },
    },
  ],
};
