import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/store/user.store';

// Routes
import auth from './routes/auth';
import users from './routes/users';
import roles from './routes/roles';
import journals from './routes/journals';
import merchants from './routes/merchants';
import domainNames from './routes/domainNames';
import wallets from './routes/wallets';
import currencies from './routes/currencies';
import invoicing from './routes/invoicing';
import transactions from './routes/transactions';
import services from './routes/services';
import providers from './routes/providers';
import gateways from './routes/gateways';
import securityAccessLists from './routes/securityAccessLists';
import systemEndpoints from './routes/systemEndpoints';
import protocols from './routes/protocols';
// import exchangerRates from './routes/exchangerRates';
// import exchangerProviders from './routes/exchangerProviders';
import techPayouts from './routes/techPayouts';
import registries from './routes/registries';
import cascadingTemplates from './routes/cascadingTemplates';
import paymentForms from './routes/paymentForms';
import reports from './routes/reports';
import reportRegistries from './routes/reportRegistries';
import reportsExtra from './routes/reportsExtra';
import paymentCards from './routes/paymentCards';
import cardsGeo from './routes/cardsGeo';
import cardsKeys from './routes/cardsKeys';
import cryptoGatewayNotifications from './routes/cryptoGatewayNotifications';
import cryptoGatewayProviders from './routes/cryptoGatewayProviders';
import commissionsFiat from './routes/commissionsFiat';
import commissionsCrypto from './routes/commissionsCrypto';
import registriesNew from './routes/registriesNew';
import techPayoutsNew from './routes/techPayoutsNew';
import vouchers from './routes/vouchers';
import exchangeCurrencies from './routes/exchangeCurrencies';
import exchangeRateProviders from './routes/exchangeRateProviders';
import exchangeRates from './routes/exchangeRates';
import exchangeScenarios from './routes/exchangeScenarios';
import exchangePairs from '@/router/routes/exchangePairs';
import exchangeWidgets from '@/router/routes/exchangeWidgets';
import exchangeOrders from '@/router/routes/exchangeOrders';
import merchantGroups from '@/router/routes/merchantGroups';
import exchangeBalanceSync from './routes/exchangeBalanceSync';
import serviceFieldTemplates from './routes/serviceFieldTemplates';
import amlProtocols from './routes/amlProtocols';
import amlGateways from '@/router/routes/amlGateways';
import amlRules from '@/router/routes/amlRules';
import amlChecks from '@/router/routes/amlChecks';
import amlQuarantine from '@/router/routes/amlQuarantine';
import systemEmails from '@/router/routes/systemEmails';
import i18n from '@/i18n';
import reportsFiles from '@/router/routes/reportsFiles';
import walletMoveBalanceList from '@/router/routes/walletMoveBalanceList';

const routes = [
  ...auth,
  {
    path: '/',
    component: () => import('@/views/Home.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import('@/views/dashboard/Dashboard.vue'),
        meta: {
          breadcrumbs: [{ text: 'routes.main_page', to: 'dashboard', disabled: true }],
        },
      },
      {
        path: '/settings/:initTab?',
        name: 'userSettings',
        component: () => import('@/views/auth/Settings.vue'),
        props: true,

        meta: {
          breadcrumbs: [{ text: 'routes.main_page', to: 'dashboard', disabled: false }, { text: 'routes.settings' }],
        },
      },
      // Routes
      users,
      roles,
      journals,
      merchants,
      domainNames,
      wallets,
      currencies,
      invoicing,
      transactions,
      services,
      providers,
      gateways,
      securityAccessLists,
      systemEndpoints,
      protocols,
      // exchangerRates,
      // exchangerProviders,
      techPayouts,
      registries,
      cascadingTemplates,
      paymentForms,
      reports,
      reportRegistries,
      reportsExtra,
      paymentCards,
      cardsGeo,
      cardsKeys,
      cryptoGatewayNotifications,
      commissionsFiat,
      commissionsCrypto,
      ...cryptoGatewayProviders,
      registriesNew,
      techPayoutsNew,
      vouchers,
      exchangeCurrencies,
      exchangeRateProviders,
      exchangeRates,
      exchangeScenarios,
      exchangePairs,
      exchangeWidgets,
      exchangeOrders,
      merchantGroups,
      exchangeBalanceSync,
      serviceFieldTemplates,
      amlProtocols,
      amlGateways,
      amlRules,
      amlChecks,
      amlQuarantine,
      systemEmails,
      reportsFiles,
      walletMoveBalanceList,
    ],
  },
  { path: '/:pathMatch(.*)*', redirect: { name: 'dashboard' } },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 });
      }, 110);
    });
  },
});

/**
 * Middleware
 */
router.beforeEach((to, from, next) => {
  // Add page title by first breadcrumb of the page
  const lastBreadcrumbTranslation = (to.meta.breadcrumbs as any)?.slice()?.pop()?.text;
  if (lastBreadcrumbTranslation) {
    document.title = i18n.global.t(lastBreadcrumbTranslation);
  } else {
    document.title = window.__title;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const $user = useUserStore();
    if (!$user.isLoggedIn || !localStorage.getItem('_token')) {
      $user.logout();
      next('/login');
    }
    next();
  } else {
    next();
  }
});

/**
 * Error handler
 */
router.onError((err, to) => {
  if (
    err?.message?.includes?.('Failed to fetch dynamically imported module') ||
    err?.message?.includes?.('Importing a module script failed') // ||
    // err?.message?.includes?.('Unable to preload CSS vue')
  ) {
    if (!localStorage.getItem('vuetify:dynamic-reload')) {
      console.log('Reloading page to fix dynamic import error');
      localStorage.setItem('vuetify:dynamic-reload', 'true');
      location.assign(to.fullPath);
    } else {
      console.error('Dynamic import error, reloading page did not fix it', err);
    }
  } else {
    console.error(err);
  }
});

router.isReady().then(() => {
  localStorage.removeItem('vuetify:dynamic-reload');
});

export default router;
