import axios from 'axios';
import { getDefaultTimezone, getTimezoneOffset } from '@/helpers/timezone';
import { useUserStore } from '@/store/user.store';
import { useSnackbar } from '@/composables/snackbar';

const api = axios.create({
  // Set VITE_APP_LOCAL_BASE_URL_API to .env to make it work with provided host
  baseURL: import.meta.env.VITE_APP_LOCAL_BASE_URL_API || location.origin,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    Timezone: getTimezoneOffset(getDefaultTimezone()),
  },
  // TODO Check if all works working fine with disable credentials
  // withCredentials: true,

  /**
   * It converts query from ?item[]=1&item[]=2 to ?item=1&item=2
   * It's needed for right handling by backend
   * @param params
   * @returns
   */
  paramsSerializer: (params) => {
    let result = '';
    for (const key in params) {
      if (params[key] === null || params[key] === undefined) {
        continue;
      }
      if (Array.isArray(params[key])) {
        params[key].forEach((value: any) => {
          value = encodeURIComponent(value);
          result += `${key}=${value}&`;
        });
      } else {
        const val = encodeURIComponent(params[key]);
        result += `${key}=${val}&`;
      }
    }
    return result.substr(0, result.length - 1);
  },
});

api.interceptors.request.use(
  (config) => {
    if (localStorage.getItem('_token')) {
      config.headers.Authorization = 'Bearer ' + localStorage.getItem('_token');
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log('REQ ERROR', error);

    if (error.response) {
      if (error?.response?.status === 401) {
        const user = useUserStore();
        return user.logout();
      }
    }

    const { errorSnackbar } = useSnackbar();

    if (error?.response?.data?.code !== 1000) {
      errorSnackbar({
        text: error?.response?.data?.error || error?.response?.data?.detail || error?.message,
      });
    }

    throw error?.response?.data;
  }
);

export default api;
