import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  usersGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/users/`, {
      params,
    });
  },
  usersSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/users/create/`, params);
  },
  usersUpdate: async ({ id, ...params }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/users/update/${id}/`, params);
  },
  usersDelete: async (params: Record<string, unknown>) => {
    return await api.delete(`/${DEFAULT_SERVICE}/users/${params.id}/`);
  },
  usersRenew: async (id: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/users/renew/${id}/`);
  },
  usersActiveToggle: async (params: { id: any }) => {
    return await api.delete(`/${DEFAULT_SERVICE}/users/${params.id}/`);
  },
  usersSearch: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/users/search/`, {
      params,
    });
  },
};
