export default {
  name: '',
  path: 'exchange-rates',
  component: () => import('@/views/exchangeRates/ExchangeRatesIndex.vue'),
  children: [
    {
      path: '',
      name: 'exchangeRates',
      component: () => import('@/views/exchangeRates/ExchangeRates.vue'),
      props: true,
      meta: {
        module: 'exchangeRates',
        breadcrumbs: [
          {
            text: 'routes.exchange_rates',
            exact: true,
            to: { name: 'exchangeRates' },
            disabled: true,
          },
        ],
      },
    },
    {
      name: 'exchangeRatesCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/exchangeRates/ExchangeRatesCreate.vue'),
      meta: {
        module: 'exchangeRates',
        breadcrumbs: [
          {
            text: 'routes.exchange_rates',
            exact: true,
            to: { name: 'exchangeRates' },
            disabled: false,
          },
          { text: 'routes.exchange_rates_create' },
        ],
      },
    },
    {
      name: 'exchangeRatesUpdate',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/exchangeRates/ExchangeRatesUpdate.vue'),
      meta: {
        module: 'exchangeRates',
        breadcrumbs: [
          {
            text: 'routes.exchange_rates',
            exact: true,
            to: { name: 'exchangeRates' },
            disabled: false,
          },
          { text: 'routes.exchange_rates_edit' },
        ],
      },
    },
  ],
};
