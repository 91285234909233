import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  merchantGroupsGetOne: async ({ id, params }: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/merchants/groups/${id}/`, {
      params,
    });
  },
  merchantGroupsUpdate: async ({ id, ...params }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/merchants/groups/${id}/`, params);
  },
  merchantGroupsDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/merchants/groups/${id}/`);
  },
  merchantGroupsGet: async ({ ...params }: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/merchants/groups/`, {
      params,
    });
  },
  merchantGroupsSet: async ({ ...params }: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/merchants/groups/`, params);
  },
  merchantGroupsSearch: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/merchants/groups/search/`, {
      params,
    });
  },
};
