<template>
  <v-app class="app" :class="`theme-${theme}`">
    <!-- <modal-wrapper /> -->

    <!-- Utils components -->
    <app-loader />
    <app-snakbar />
    <app-dialogs />

    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </v-app>
</template>

<script lang="ts" setup>
  import AppLoader from './components/AppLoader.vue';
  import AppSnakbar from './components/AppSnakbar.vue';
  import AppDialogs from './components/AppDialogs.vue';

  const theme = window.__app_theme;

  // import ModalWrapper from '@/spa/common/modals/ModalWrapper.vue';
</script>
