import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  invoicingGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/invoicing/invoices/`, {
      params,
    });
  },
  invoicingGetOne: async (id: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/invoicing/invoices/${id}/`);
  },
  invoicingSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/invoicing/invoices/`, params);
  },
  invoicingCancel: async ({ id }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/invoicing/invoices/${id}/cancel`);
  },
  getInvoicingCommission: async ({ wallet_id, service_id, amount } = {} as any) => {
    return await api.post(`/${DEFAULT_SERVICE}/invoicing/commissions/${wallet_id}/${service_id}/`, { amount });
  },

  invoicingMerchantsSearch: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/invoicing/merchants/`, {
      params,
    });
  },
  invoicingWalletsSearch: async ({ merchant_id, ...params }: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/invoicing/merchants/${merchant_id}/wallets/`, {
      params,
    });
  },
  invoicingServicesSearch: async ({ wallet_id, ...params }: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/invoicing/wallets/${wallet_id}/services/`, {
      params,
    });
  },
  invoicingServiceGetOne: async (wallet_id: any, service_id: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/invoicing/wallets/${wallet_id}/services/${service_id}`);
  },
};
