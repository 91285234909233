export default {
  name: '',
  path: 'security-access-lists',
  component: () => import('@/views/security/SecurityAccessListsIndex.vue'),
  children: [
    {
      path: '',
      name: 'security-access-lists',
      component: () => import('@/views/security/SecurityAccessLists.vue'),
      props: true,
      meta: {
        module: 'AccessLists',
        breadcrumbs: [
          { text: 'routes.access_list', exact: true, to: { name: 'security-access-lists' }, disabled: true },
        ],
      },
    },
    {
      name: 'securityAccessListsCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/security/SecurityAccessListsCreate.vue'),
      meta: {
        module: 'AccessLists',
        breadcrumbs: [
          { text: 'routes.access_list', exact: true, to: { name: 'security-access-lists' }, disabled: false },
          { text: 'routes.access_create' },
        ],
      },
    },
    {
      name: 'securityAccessListsUpdate',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/security/SecurityAccessListsUpdate.vue'),
      meta: {
        module: 'AccessLists',
        breadcrumbs: [
          { text: 'routes.access_list', exact: true, to: { name: 'security-access-lists' }, disabled: false },
          { text: 'routes.access_edit' },
        ],
      },
    },
  ],
};
