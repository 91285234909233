import api from '@/api';
import { CARDS_SERVICE } from './constants';

export default {
  cardsGeoGet: async (params: any) => {
    return await api.get(`/${CARDS_SERVICE}/geo/`, {
      params,
    });
  },
  cardsGeoSearch: async (params: any) => {
    return await api.get(`/${CARDS_SERVICE}/geo/`, {
      params,
    });
  },
  cardsGeoGetOne: async ({ id }: any) => {
    return await api.get(`/${CARDS_SERVICE}/geo/${id}/`);
  },
  cardsGeoSet: async (params: any) => {
    return await api.post(`/${CARDS_SERVICE}/geo/`, params);
  },
  cardsGeoUpdate: async ({ id, ...params }: any) => {
    return await api.patch(`/${CARDS_SERVICE}/geo/${id}/`, params);
  },
  cardsGeoDelete: async (params: Record<string, unknown>) => {
    return await api.delete(`/${CARDS_SERVICE}/geo/${params.id}/`);
  },
};
