import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  cascadingFiltersGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/cascading/filters/`, {
      params,
    });
  },

  cascadingFiltersSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/cascading/filters/`, params);
  },

  cascadingFiltersUpdate: async ({ id, ...params }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/cascading/filters/${id}/`, params);
  },

  cascadingFiltersDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/cascading/filters/${id}/`);
  },
};
