import api from '@/api';
import { DEFAULT_SERVICE, LOGS_SERVICE } from './constants';

export default {
  transactionsGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/transactions/`, {
      params,
    });
  },
  transactionGetOne: async ({ id }: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/transactions/${id}/`);
  },
  transactionData: async ({ id }: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/transactions/data/${id}/`);
  },
  transactionsSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/transactions/create/`, params);
  },
  transactionsUpdate: async (id: any, params: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/transactions/update/${id}/`, params);
  },
  transactionsDelete: async (params: { id: any }) => {
    return await api.delete(`/${DEFAULT_SERVICE}/transactions/${params.id}/`);
  },
  transactionPaymentLog: async ({ id }: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/transactions/${id}/payment_logs/`);
  },
  transactionProviderLog: async ({ token, ...params }: any) => {
    return await api.get(`/${LOGS_SERVICE}/providers/${token}/logs/`, {
      params,
    });
  },
  transactionCbGet: async ({ id, ...params }: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/transactions/${id}/callback_logs/`, {
      params,
    });
  },
  transactionCbCreate: async ({ id }: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/transactions/${id}/callback/`);
  },
  transactionChangeStatus: async ({ id, ...params }: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/transactions/${id}/change_status/`, params);
  },
  transactionCheckStatus: async ({ id }: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/transactions/${id}/check_status/`);
  },
  transactionFinalizeStatus: async ({ id, scenario }: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/transactions/${id}/finalize/`, {
      scenario,
    });
  },
  transactionKytFinalize: async ({ id, ...params }: any) => {
    return await api.post(`${DEFAULT_SERVICE}/transactions/${id}/kyt_confirm/`, params);
  },
};
