import api from '@/api';
import { EXCHANGE_SERVICE } from './constants';

export default {
  exchangeRateProvidersGet: async (params: any) => {
    return await api.get(`/${EXCHANGE_SERVICE}/providers/`, {
      params,
    });
  },
  exchangeRateProviderGet: async (params: any) => {
    return await api.get(`/${EXCHANGE_SERVICE}/providers/${params.id}/`);
  },
  exchangeRateProvidersSet: async (params: any) => {
    return await api.post(`/${EXCHANGE_SERVICE}/providers/`, params);
  },
  exchangeRateProvidersUpdate: async ({ id, ...params }: any) => {
    return await api.patch(`/${EXCHANGE_SERVICE}/providers/${id}/`, params);
  },
  exchangeRateProvidersDelete: async ({ id }: any) => {
    return await api.delete(`/${EXCHANGE_SERVICE}/providers/${id}/`);
  },
  exchangeRateProvidersSearch: async (params: any) => {
    return await api.get(`/${EXCHANGE_SERVICE}/providers/search/`, {
      params,
    });
  },
};
