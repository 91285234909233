import api from '@/api';
import { EXCHANGE_SERVICE } from './constants';

export default {
  exchangeRatesOverloadGet: async ({ rate_id, ...params }: any) => {
    return await api.get(`/${EXCHANGE_SERVICE}/rates/${rate_id}/rate_overloads/`, {
      params,
    });
  },
  exchangeRatesOverloadSet: async ({ rate_id, ...params }: any) => {
    return await api.post(`/${EXCHANGE_SERVICE}/rates/${rate_id}/rate_overloads/`, params);
  },
  exchangeRatesOverloadUpdate: async ({ rate_id, id, ...params }: any) => {
    return await api.patch(`/${EXCHANGE_SERVICE}/rates/${rate_id}/rate_overloads/${id}/`, params);
  },
  exchangeRatesOverloadDelete: async ({ rate_id, id }: any) => {
    return await api.delete(`/${EXCHANGE_SERVICE}/rates/${rate_id}/rate_overloads/${id}/`);
  },
};
