import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  registriesGet: async (params: any) => {
    if (params.id) {
      return await api.get(`/${DEFAULT_SERVICE}/tech_payout/registries/${params.id}/`);
    }

    return await api.get(`/${DEFAULT_SERVICE}/tech_payout/registries/`, {
      params,
    });
  },
};
