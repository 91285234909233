export default {
  name: '',
  path: 'reports-files',
  component: () => import('@/views/reportsFiles/ReportsFilesIndex.vue'),
  children: [
    {
      path: '',
      name: 'reports-files',
      component: () => import('@/views/reportsFiles/ReportsFiles.vue'),
      props: true,
      meta: {
        // module: 'reports',
        breadcrumbs: [{ text: 'routes.report_files', exact: true, to: { name: 'reports-files' }, disabled: true }],
      },
    },
  ],
};
