export default {
  name: '',
  path: 'crypto-gateway-notifications',
  component: () => import('@/views/cryptoGatewayNotifications/CryptoGatewayNotificationsIndex.vue'),
  children: [
    {
      path: '',
      name: 'crypto-gateway-notifications',
      component: () => import('@/views/cryptoGatewayNotifications/CryptoGatewayNotifications.vue'),
      props: true,
      meta: {
        module: 'crypto_gateways',
        breadcrumbs: [
          {
            text: 'routes.crypto_gateway_notifications',
            exact: true,
            to: { name: 'crypto-gateway-notifications' },
            disabled: true,
          },
        ],
      },
    },
    {
      name: 'crypto-gateway-notifications-create',
      path: 'create',
      props: true,
      component: () => import('@/views/cryptoGatewayNotifications/CryptoGatewayNotificationsCreate.vue'),
      meta: {
        module: 'currencies',
        breadcrumbs: [
          {
            text: 'routes.crypto_gateway_notifications',
            exact: true,
            to: { name: 'crypto-gateway-notifications' },
            disabled: false,
          },
          { text: 'routes.crypto_gateway_notifications_create' },
        ],
      },
    },
    {
      name: 'crypto-gateway-notifications-update',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/cryptoGatewayNotifications/CryptoGatewayNotificationsUpdate.vue'),
      meta: {
        module: 'currencies',
        breadcrumbs: [
          {
            text: 'routes.crypto_gateway_notifications',
            exact: true,
            to: { name: 'crypto-gateway-notifications' },
            disabled: false,
          },
          { text: 'routes.crypto_gateway_notifications_update' },
        ],
      },
    },
  ],
};
