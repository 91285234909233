export default {
  name: '',
  path: 'service-field-templates',
  component: () => import('@/views/serviceFieldTemplates/ServiceFieldTemplatesIndex.vue'),
  children: [
    {
      path: '',
      name: 'serviceFieldTemplates',
      component: () => import('@/views/serviceFieldTemplates/ServiceFieldTemplates.vue'),
      props: true,
      meta: {
        module: 'service_field_values_templates',
        breadcrumbs: [
          {
            text: 'routes.service_field_values_templates',
            exact: true,
            to: { name: 'serviceFieldTemplates' },
            disabled: true,
          },
        ],
      },
    },
    {
      name: 'serviceFieldTemplatesCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/serviceFieldTemplates/ServiceFieldTemplatesCreate.vue'),
      meta: {
        module: 'service_field_values_templates',
        breadcrumbs: [
          {
            text: 'routes.service_field_values_templates',
            exact: true,
            to: { name: 'serviceFieldTemplates' },
            disabled: false,
          },
          { text: 'routes.service_field_values_templates_create' },
        ],
      },
    },
    {
      name: 'serviceFieldTemplatesUpdate',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/serviceFieldTemplates/ServiceFieldTemplatesUpdate.vue'),
      meta: {
        module: 'service_field_values_templates',
        breadcrumbs: [
          {
            text: 'routes.service_field_values_templates',
            exact: true,
            to: { name: 'serviceFieldTemplates' },
            disabled: false,
          },
          { text: 'routes.service_field_values_templates_edit' },
        ],
      },
    },
  ],
};
