import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  techPayoutsGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/tech_payout/`, {
      params,
    });
  },
  techPayoutsSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/tech_payout/`, params);
  },
  techPayoutsUpdate: async (id: any, params: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/tech_payout/${id}`, params);
  },
  techPayoutGet: async (id: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/tech_payout/${id}/`);
  },
  techPayoutServicesSearch: async (params: { wallet_id: any }) => {
    return await api.get(`/${DEFAULT_SERVICE}/tech_payout/services/${params.wallet_id}/`);
  },
  techPayoutsRegistriesGet: async () => {
    return await api.get(`/${DEFAULT_SERVICE}/tech_payout/registries/active/`);
  },
  techPayoutsRegistriesSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/tech_payout/registries/active/`, params);
  },
  techPayoutsRegistriesDelete: async () => {
    return await api.delete(`/${DEFAULT_SERVICE}/tech_payout/registries/active/`);
  },
  techPayoutsRegistriesValidate: async (formData: FormData) => {
    return await api.post(`/${DEFAULT_SERVICE}/tech_payout/registries/validate`, formData, {
      headers: {
        'Content-Type': `multipart/form-data`, // boundary=${formData._boundary}
      },
    });
  },
  techPayoutVerify: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/tech_payout/validate/`, params);
  },
  techPayoutServiceInfo: async (id: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/tech_payout/service_info/${id}/`);
  },
};
