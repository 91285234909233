<template>
  <div></div>
</template>

<script setup lang="ts">
  import { EventBus } from '@/helpers/eventBus';
  import { onMounted } from 'vue';
  import { useSnackbar } from 'vuetify-use-dialog';

  const createSnackbar = useSnackbar();

  onMounted(() => {
    EventBus.$on('v-snackbar', (...args: Parameters<typeof createSnackbar>) => {
      createSnackbar(...args);
    });
  });
</script>
