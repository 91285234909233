import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  walletsGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/wallets/`, {
      params,
    });
  },
  walletGet: async (id: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/wallets/${id}/`);
  },
  walletsSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/wallets/create/`, params);
  },
  walletsUpdate: async ({ id, ...params }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/wallets/update/${id}/`, params);
  },
  walletsDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/wallets/${id}/`);
  },
  walletsHistoryGet: async ({ id, ...params }: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/wallets/history/${id}/`, {
      params,
    });
  },
  walletsBalanceUpdate: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/wallets/balance/`, params);
  },
  walletsSearch: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/wallets/search/`, {
      params,
    });
  },
  walletsAddressesGet: async ({ id, ...params }: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/wallets/${id}/addresses/`, {
      params,
    });
  },
  walletsAddressesSet: async ({ id, ...payload }: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/wallets/${id}/addresses/`, payload);
  },
  walletsAddressesDelete: async ({ wallet_id, id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/wallets/${wallet_id}/addresses/${id}/ `);
  },
  walletsRollingRoll: async ({ id, ...params }: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/wallets/${id}/rolling/roll/`, params);
  },
  walletsRollingUnroll: async ({ id, ...params }: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/wallets/${id}/rolling/unroll/`, params);
  },
  walletMoveBalance: async ({ id, ...params }: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/wallets/${id}/move_balance/`, params);
  },
  walletMoveBalanceList: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/wallets/move_balance/`, {
      params,
    });
  }
};
