export default {
  name: '',
  path: 'domain-names',
  component: () => import('@/views/domainNames/DomainNamesIndex.vue'),
  children: [
    {
      path: '',
      name: 'domain_names',
      component: () => import('@/views/domainNames/DomainNames.vue'),
      props: true,
      meta: {
        module: 'domain-names',
        breadcrumbs: [{ text: 'routes.domain_names', exact: true, to: { name: 'domain_names' }, disabled: true }],
      },
    },
    {
      name: 'domainNamesCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/domainNames/DomainNamesCreate.vue'),
      meta: {
        module: 'domain-names',
        breadcrumbs: [
          { text: 'routes.domain_names', exact: true, to: { name: 'domain_names' }, disabled: false },
          { text: 'routes.domain_names_create' },
        ],
      },
    },
    {
      name: 'domainNamesUpdate',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/domainNames/DomainNamesUpdate.vue'),
      meta: {
        module: 'domain-names',
        breadcrumbs: [
          { text: 'routes.domain_names', exact: true, to: { name: 'domain_names' }, disabled: false },
          { text: 'routes.domain_names_edit' },
        ],
      },
    },
  ],
};
