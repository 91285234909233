<template>
  <v-dialog v-model="isVisible" v-bind="payload?.dialogProps || {}">
    <component
      v-if="payload"
      :is="payload.component"
      v-bind="payload.props"
      @success="isVisible = false"
      @reject="isVisible = false"
    />
  </v-dialog>
</template>

<script setup lang="ts">
  import { EventBus } from '@/helpers/eventBus';
  import { onMounted, ref, unref, shallowRef, type Component } from 'vue';

  interface DialogPayload {
    component: Component;
    props: Record<string, unknown>;
    dialogProps?: Record<string, unknown>;
  }

  const isVisible = ref(false);
  const payload = shallowRef<DialogPayload | null>(null);

  onMounted(() => {
    EventBus.$on('v-dialog', (dialogPayload: DialogPayload) => {
      payload.value = dialogPayload;
      isVisible.value = true;
    });
  });
</script>
