import api from '@/api';
import { DEFAULT_SERVICE, LOGS_SERVICE } from "./constants";

export default {
  merchantsGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/merchants/`, {
      params,
    });
  },
  merchantsSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/merchants/create/`, params);
  },
  merchantsUpdate: async ({ id, ...params }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/merchants/update/${id}/`, params);
  },
  merchantView: async (id: any, params: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/merchants/${id}/`, params);
  },
  merchantsDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/merchants/${id}/`);
  },
  merchantsSearch: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/merchants/search/`, {
      params,
    });
  },
  merchantServiceGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/merchants/merchant_service/`, {
      params,
    });
  },
  merchantServiceSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/merchants/merchant_service/create/`, params);
  },
  merchantServiceDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/merchants/merchant_service/${id}/`);
  },

  merchantLogsGet: async ({ id, ...params }: any) => {
    return await api.get(`/${LOGS_SERVICE}/merchants/${id}/logs/`, {
      params,
    });
  },
  merchantEndpointsGet: async ({ id, ...params }: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/merchants/${id}/endpoints/`, {
      params,
    });
  },

  merchantEndpointsSet: async ({ id, ...params }: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/merchants/${id}/endpoints/`, params);
  },
  merchantEndpointsUpdate: async (id: any, params: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/merchants/endpoints/${id}/`, params);
  },
  merchantEndpointsDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/merchants/endpoints/${id}/`);
  },
  merchantEndpointGet: async ({ id }: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/merchants/endpoints/${id}/`);
  },

  merchantKeysGet: async ({ id, ...params }: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/merchants/${id}/keys/`, {
      params,
    });
  },
  merchantKeyGet: async (id: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/merchant/keys/${id}/`);
  },
  merchantKeysSet: async ({ id, ...params }: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/merchants/${id}/keys/`, params);
  },
  merchantKeysDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/merchants/keys/${id}/`);
  },

  merchantDomainNamesGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/merchants/merchant_domain/`, {
      params,
    });
  },
  merchantDomainNamesSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/merchants/merchant_domain/`, params);
  },
  merchantDomainNamesDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/merchants/merchant_domain/${id}/`);
  },
};
