export default {
  name: '',
  path: 'wallet-move-balance-list',
  component: () => import('@/views/walletMoveBalanceList/WalletMoveBalanceListIndex.vue'),
  children: [
    {
      path: '',
      name: 'wallet_move_balance_list',
      component: () => import('@/views/walletMoveBalanceList/WalletMoveBalanceList.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          {
            text: 'routes.moving_balance_list',
            exact: true,
            to: { name: 'wallet_move_balance_list' },
            disabled: true,
          },
        ],
      },
    },
  ],
};
