import api from '@/api';
import { TECH_PAYOUTS_SERVICE } from './constants';

export default {
  registriesNewGet: async (params: any) => {
    if (params.id) {
      return await api.get(`/${TECH_PAYOUTS_SERVICE}/registries/${params.id}/`);
    }

    return await api.get(`/${TECH_PAYOUTS_SERVICE}/registries/`, {
      params,
    });
  },
  registriesNewCreate: async (params: any) => {
    return api.post(`/${TECH_PAYOUTS_SERVICE}/registries/`, params, {
      headers: {
        'Content-Type': `multipart/form-data`, // boundary=${formData._boundary}
      },
    });
  },
  registriesNewApprove: async ({ registryId, ...payload }: any) => {
    return api.post(`/${TECH_PAYOUTS_SERVICE}/registries/${registryId}/approve/`, payload);
  },
  registriesNewReject: async (registryId: any) => {
    return api.post(`/${TECH_PAYOUTS_SERVICE}/registries/${registryId}/reject/`);
  },
  registriesNewCancel: async (registryId: any) => {
    return api.post(`/${TECH_PAYOUTS_SERVICE}/registries/${registryId}/cancel/`);
  },
};
