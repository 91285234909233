export default {
  name: '',
  path: 'journals',
  component: () => import('@/views/journals/JournalsIndex.vue'),
  children: [
    {
      path: '',
      name: 'journals',
      component: () => import('@/views/journals/Journals.vue'),
      props: true,
      meta: {
        module: 'journals',
        breadcrumbs: [{ text: 'routes.journals', exact: true, to: { name: 'journals' }, disabled: true }],
      },
    },
  ],
};
