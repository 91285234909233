import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  exchangerProvidersGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/exchanger/providers`, {
      params,
    });
  },
  exchangerProvidersSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/exchanger/providers/`, params);
  },
  exchangerProvidersUpdate: async ({ id, ...params }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/exchanger/providers/${id}`, params);
  },
  exchangerProvidersDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/exchanger/providers/${id}/`);
  },
};
