import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  getPermissions: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/users/permissions/`, {
      params,
    });
  },
  postPermission: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/users/permissions/create/`, params);
  },
  deletePermission: async (params: { id: any }) => {
    return await api.delete(`/${DEFAULT_SERVICE}/users/permissions/${params.id}/`);
  },

  batchPermissionsSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/users/permissions/batch/`, params);
  },
  batchPermissionsDelete: async (params: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/users/permissions/batch/`, { data: params });
  },
};
