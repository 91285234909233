export default {
  name: '',
  path: 'aml-rules',
  component: () => import('@/views/amlRules/AmlRulesIndex.vue'),
  children: [
    {
      path: '',
      name: 'aml-rules',
      component: () => import('@/views/amlRules/AmlRules.vue'),
      props: true,
      meta: {
        breadcrumbs: [{ text: 'routes.aml_rules', exact: true, to: { name: 'aml-rules' }, disabled: true }],
      },
    },
    {
      path: 'create',
      name: 'aml-rules-create',
      component: () => import('@/views/amlRules/AmlRulesCreate.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          { text: 'routes.aml_rules', exact: true, to: { name: 'aml-rules' } },
          { text: 'routes.aml_rules_create' },
        ],
      },
    },
    {
      path: 'update/:id',
      name: 'aml-rules-update',
      component: () => import('@/views/amlRules/AmlRulesUpdate.vue'),
      props: true,
      meta: {
        // module: 'reports',
        breadcrumbs: [
          { text: 'routes.aml_rules', exact: true, to: { name: 'aml-rules' } },
          { text: 'routes.aml_rules_edit' },
        ],
      },
    },
  ],
};
