import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  currenciesGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/wallets/currencies/`, {
      params,
    });
  },
  currenciesSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/wallets/currencies/create/`, params);
  },
  currenciesUpdate: async ({ id, ...params }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/wallets/currencies/${id}/`, params);
  },
  currenciesDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/wallets/currencies/${id}/`);
  },
  currenciesSearch: async (params?: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/wallets/currencies/search/`, {
      params,
    });
  },

  onlyCurrenciesSearch: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/currencies/search/`, {
      params,
    });
  },
};
