export default {
  name: '',
  path: 'aml-gateways',
  component: () => import('@/views/amlGateways/AmlGatewaysIndex.vue'),
  children: [
    {
      path: '',
      name: 'aml-gateways',
      component: () => import('@/views/amlGateways/AmlGateways.vue'),
      props: true,
      meta: {
        breadcrumbs: [{ text: 'routes.aml_gateways', exact: true, to: { name: 'aml-gateways' }, disabled: true }],
      },
    },
    {
      path: 'create',
      name: 'aml-gateways-create',
      component: () => import('@/views/amlGateways/AmlGatewaysCreate.vue'),
      props: true,
      meta: {
        breadcrumbs: [
          { text: 'routes.aml_gateways', exact: true, to: { name: 'aml-gateways' } },
          { text: 'routes.aml_gateways_create' },
        ],
      },
    },
    {
      path: 'update/:id',
      name: 'aml-gateways-update',
      component: () => import('@/views/amlGateways/AmlGatewaysUpdate.vue'),
      props: true,
      meta: {
        // module: 'reports',
        breadcrumbs: [
          { text: 'routes.aml_gateways', exact: true, to: { name: 'aml-gateways' } },
          { text: 'routes.aml_gateways_update' },
        ],
      },
    },
  ],
};
