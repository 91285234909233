export default {
  name: '',
  path: 'protocols',
  component: () => import('@/views/protocols/ProtocolsIndex.vue'),
  children: [
    {
      path: '',
      name: 'protocols',
      component: () => import('@/views/protocols/Protocols.vue'),
      props: true,
      meta: {
        module: 'Protocols',
        breadcrumbs: [{ text: 'routes.protocols', exact: true, disabled: true }],
      },
    },
  ],
};
