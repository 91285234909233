import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  exchangerRatesGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/exchanger/rates/`, {
      params,
    });
  },
  rateOverloadsGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/exchanger/rate_overloads`, {
      params,
    });
  },
  rateOverloadGet: async (id: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/exchanger/rate_overloads/${id}/`);
  },
  rateOverloadSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/exchanger/rate_overloads/`, params);
  },
  rateOverloadUpdate: async (id: any, params: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/exchanger/rate_overloads/${id}/`, params);
  },
  rateOverloadDelete: async (params: { id: any }) => {
    return await api.delete(`/${DEFAULT_SERVICE}/exchanger/rate_overloads/${params.id}/`);
  },
};
