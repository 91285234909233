import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  cascadingTemplatesGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/cascading/templates/`, {
      params,
    });
  },
  cascadingTemplatesSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/cascading/templates/`, params);
  },
  cascadingTemplatesUpdate: async ({ id, ...params }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/cascading/templates/${id}/`, params);
  },
  cascadingTemplatesDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/cascading/templates/${id}/`);
  },
  cascadingTemplatesSearch: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/cascading/templates/search/`, {
      params,
    });
  },
};
