import api from '@/api';
import { REPORTS_REGISTRIES_SERVICE } from '@/api/services/constants';

export default {
  reportsFilesGet: async (params: Record<string, unknown>) => {
    return await api.get(`/${REPORTS_REGISTRIES_SERVICE}/reports/files/`, { params });
  },
  reportFilesDownload: async (id: number) => {
    return await api.get(`/${REPORTS_REGISTRIES_SERVICE}/reports/files/${id}/download/`, {
      responseType: 'blob',
      timeout: 10000000,
    });
  },
  reportFilesDelete: async ({ id }: any) => {
    return await api.delete(`/${REPORTS_REGISTRIES_SERVICE}/reports/files/${id}/`);
  },
};
