export default {
  name: '',
  path: 'payment-cards',
  component: () => import('@/views/paymentCards/PaymentCardsIndex.vue'),
  children: [
    {
      path: '',
      name: 'payment-cards',
      component: () => import('@/views/paymentCards/PaymentCards.vue'),
      props: true,
      meta: {
        // module: 'reports',
        breadcrumbs: [{ text: 'routes.payment_cards', exact: true, to: { name: 'payment-cards' }, disabled: true }],
      },
    },
    {
      path: 'update/:id',
      name: 'payment-cards-update',
      component: () => import('@/views/paymentCards/PaymentCardsUpdate.vue'),
      props: true,
      meta: {
        // module: 'reports',
        breadcrumbs: [
          { text: 'routes.payment_cards', exact: true, to: { name: 'payment-cards' } },
          { text: 'routes.payment_cards_update' },
        ],
      },
    },
  ],
};
