import api from '@/api';
import { CARDS_SERVICE } from './constants';

export default {
  paymentCardsGet: async (params: any) => {
    return await api.get(`/${CARDS_SERVICE}/cards/`, {
      params,
    });
  },
  paymentCardsUpdate: async ({ id, ...payload }: any) => {
    return await api.patch(`/${CARDS_SERVICE}/cards/${id}/`, payload);
  },
  paymentCardGet: async ({ id, ...params }: any) => {
    return await api.get(`/${CARDS_SERVICE}/cards/${id}/`, {
      params,
    });
  },
  paymentCardsDelete: async ({ id }: any) => {
    return await api.delete(`/${CARDS_SERVICE}/cards/${id}/`);
  },
  paymentCardsStatisticGet: async ({ card_id, ...params }: any) => {
    return await api.get(`/${CARDS_SERVICE}/cards/${card_id}/statistic/`, {
      params,
    });
  },
  paymentCardsStatisticUpdate: async ({ card_id, id, ...payload }: any) => {
    return await api.patch(`/${CARDS_SERVICE}/cards/${card_id}/statistic/${id}/`, payload);
  },
};
