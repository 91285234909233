export default {
  name: '',
  path: 'tech-payouts',
  component: () => import('@/views/techPayouts/PayoutsIndex.vue'),
  children: [
    {
      path: '',
      name: 'tech-payouts',
      component: () => import('@/views/techPayouts/Payouts.vue'),
      props: true,
      meta: {
        module: 'techPayouts',
        breadcrumbs: [{ text: 'routes.tech_payouts', exact: true, to: { name: 'tech-payouts' }, disabled: true }],
      },
    },
    {
      name: 'techPayoutsCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/techPayouts/PayoutsCreate.vue'),
      meta: {
        module: 'techPayouts',
        breadcrumbs: [
          { text: 'routes.tech_payouts', exact: true, to: { name: 'tech-payouts' }, disabled: false },
          { text: 'routes.tech_payout_create' },
        ],
      },
    },
  ],
};
