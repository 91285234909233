export default {
  name: '',
  path: 'transactions',
  component: () => import('@/views/transactions/TransactionsIndex.vue'),
  children: [
    {
      path: '',
      name: 'transactions',
      component: () => import('@/views/transactions/Transactions.vue'),
      props: true,
      meta: {
        module: 'transactions',
        breadcrumbs: [{ text: 'routes.transactions', exact: true, to: { name: 'transactions' }, disabled: true }],
      },
    },
    {
      name: 'transactionDetails',
      path: 'transaction/:id',
      component: () => import('@/views/transactions/TransactionDetails.vue'),
      props: true,
      meta: {
        module: 'transactions',
        breadcrumbs: [
          { text: 'routes.transactions', exact: true, to: { name: 'transactions' }, disabled: false },
          { text: 'routes.transaction_info' },
        ],
      },
    },
  ],
};
