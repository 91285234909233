import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  cascadingGatewaysGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/cascading/gateways/`, {
      params,
    });
  },
  cascadingGatewaysGetOne: async ({ id }: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/cascading/gateways/${id}/`);
  },
  cascadingGatewaysSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/cascading/gateways/`, params);
  },
  cascadingGatewaysUpdate: async ({ id, ...params }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/cascading/gateways/${id}/`, params);
  },
  cascadingGatewaysDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/cascading/gateways/${id}/`);
  },
};
