import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  gatewaysGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/providers/gateways/`, {
      params,
    });
  },
  gatewaysSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/providers/gateways/create/`, params);
  },
  gatewaysUpdate: async ({ id, ...params }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/providers/gateways/update/${id}/`, params);
  },
  gatewaysDelete: async ({ id }: any) => {
    return await api.delete(`/${DEFAULT_SERVICE}/providers/gateways/${id}/`);
  },
  gatewaysSearch: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/providers/gateways/search/`, {
      params,
    });
  },
  gatewayCopy: async ({ id }: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/providers/gateways/${id}/copy/`);
  },
};
