export default {
  name: '',
  path: 'commissions-fiat',
  component: () => import('@/views/commissionsFiat/CommissionsFiatIndex.vue'),
  children: [
    {
      path: '',
      name: 'commissions-fiat',
      component: () => import('@/views/commissionsFiat/CommissionsFiat.vue'),
      props: true,
      meta: {
        module: 'commissions',
        breadcrumbs: [
          { text: 'routes.commissions_fiat', exact: true, to: { name: 'commissions-fiat' }, disabled: true },
        ],
      },
    },
    {
      name: 'commissionsFiatCreate',
      path: 'create',
      props: true,
      component: () => import('@/views/commissionsFiat/CommissionsFiatCreate.vue'),
      meta: {
        module: 'commissions',
        breadcrumbs: [
          { text: 'routes.commissions_fiat', exact: true, to: { name: 'commissions-fiat' }, disabled: false },
          { text: 'routes.commissions_fiat_create' },
        ],
      },
    },
    {
      name: 'commissionsFiatUpdate',
      path: 'update/:id',
      props: true,
      component: () => import('@/views/commissionsFiat/CommissionsFiatUpdate.vue'),
      meta: {
        module: 'commissions',
        breadcrumbs: [
          { text: 'routes.commissions_fiat', exact: true, to: { name: 'commissions-fiat' }, disabled: false },
          { text: 'routes.commissions_fiat_update' },
        ],
      },
    },
  ],
};
