import api from '@/api';
import { AML_SERVICE } from './constants';

export default {
  amlQuarantineGet: async (params: any) => {
    return await api.get(`/${AML_SERVICE}/quarantine/`, {
      params,
    });
  },
  amlQuarantineSearch: async (params: any) => {
    return await api.get(`/${AML_SERVICE}/quarantine/`, {
      params,
    });
  },
  amlQuarantineGetOne: async ({ id }: any) => {
    return await api.get(`/${AML_SERVICE}/quarantine/${id}/`);
  },
  // amlQuarantineSet: async (params: any) => {
  //   return await api.post(`/${AML_SERVICE}/quarantine/`, params);
  // },
  amlQuarantineUpdate: async ({ id, ...params }: any) => {
    return await api.patch(`/${AML_SERVICE}/quarantine/${id}/`, params);
  },
  amlQuarantineDelete: async (params: Record<string, unknown>) => {
    return await api.delete(`/${AML_SERVICE}/quarantine/${params.id}/`);
  },
};
