import api from '@/api';
import { REPORTS_REGISTRIES_SERVICE } from './constants';

export default {
  reportFormatsGet: async (params: Record<string, unknown>) => {
    return await api.get(`/${REPORTS_REGISTRIES_SERVICE}/formats/`, { params });
  },
  reportFormatsSearch: async (params: Record<string, unknown>) => {
    return await api.get(`/${REPORTS_REGISTRIES_SERVICE}/formats/`, { params });
  },
  reportFormatGet: async (params: Record<string, unknown>) => {
    return await api.get(`/${REPORTS_REGISTRIES_SERVICE}/formats/`, { params });
  },
  reportFormatsSet: async (payload: any) => {
    return await api.post(`/${REPORTS_REGISTRIES_SERVICE}/formats/`, payload);
  },
  reportFormatsUpdate: async ({ id, ...payload }: any) => {
    return await api.patch(`/${REPORTS_REGISTRIES_SERVICE}/formats/${id}/`, payload);
  },
  reportFormatsDelete: async (params: any) => {
    return await api.delete(`/${REPORTS_REGISTRIES_SERVICE}/formats/${params.id}/`);
  },
};
