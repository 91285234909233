import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  permissionsTableGet: async (roleId: any, params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/users/${roleId}/permissions_table/`, {
      params,
    });
  },
  permissionsTableSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/users/permissions_table/`, params);
  },
  permissionsTableDelete: async (tableId = '') => {
    return await api.delete(`/${DEFAULT_SERVICE}/users/permissions_table/${tableId}/`);
  },
};
