import api from '@/api';
import { AML_SERVICE } from './constants';

export default {
  amlProtocolsGet: async (params: any) => {
    return await api.get(`/${AML_SERVICE}/protocols/`, {
      params,
    });
  },
  amlProtocolsSearch: async (params: any) => {
    return await api.get(`/${AML_SERVICE}/protocols/search/`, {
      params,
    });
  },
  amlProtocolsGetOne: async ({ id }: any) => {
    return await api.get(`/${AML_SERVICE}/protocols/${id}/`);
  },
  amlProtocolsSet: async (params: any) => {
    return await api.post(`/${AML_SERVICE}/protocols/`, params);
  },
  amlProtocolsUpdate: async ({ id, ...params }: any) => {
    return await api.patch(`/${AML_SERVICE}/protocols/${id}/`, params);
  },
  amlProtocolsDelete: async (params: Record<string, unknown>) => {
    return await api.delete(`/${AML_SERVICE}/protocols/${params.id}/`);
  },
};
