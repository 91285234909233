import api from '@/api';
import { COMMISSIONS_SERVICE } from './constants';

export default {
  commissionsCryptoGet: async (params: any) => {
    return await api.get(`/${COMMISSIONS_SERVICE}/commissions/crypto/`, {
      params,
    });
  },
  commissionsCryptoGetOne: async ({ id }: any) => {
    return await api.get(`/${COMMISSIONS_SERVICE}/commissions/crypto/${id}/`);
  },
  commissionsCryptoSet: async (params: any) => {
    return await api.post(`/${COMMISSIONS_SERVICE}/commissions/crypto/`, params);
  },
  commissionsCryptoUpdate: async ({ id, ...params }: any) => {
    return await api.patch(`/${COMMISSIONS_SERVICE}/commissions/crypto/${id}/`, params);
  },
  commissionsCryptoDelete: async ({ id }: any) => {
    return await api.delete(`/${COMMISSIONS_SERVICE}/commissions/crypto/${id}/`);
  },
};
