import api from '@/api';
import { CRYPTO_GATEWAYS_SERVICE } from './constants';

export default {
  cryptoGatewayNotificationsGet: async (params: any) => {
    return await api.get(`/${CRYPTO_GATEWAYS_SERVICE}/alerts/`, {
      params,
    });
  },
  cryptoGatewayNotificationsSet: async (params: any) => {
    return await api.post(`/${CRYPTO_GATEWAYS_SERVICE}/alerts/`, params);
  },
  cryptoGatewayNotificationsUpdate: async ({ id, ...params }: any) => {
    return await api.patch(`/${CRYPTO_GATEWAYS_SERVICE}/alerts/${id}/`, params);
  },
  cryptoGatewayNotificationsDelete: async ({ id }: any) => {
    return await api.delete(`/${CRYPTO_GATEWAYS_SERVICE}/alerts/${id}/`);
  },
  cryptoGatewayNotificationGet: async ({ id }: any) => {
    return await api.get(`/${CRYPTO_GATEWAYS_SERVICE}/alerts/${id}/`);
  },
};
