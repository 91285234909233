export default {
  name: '',
  path: 'cards-keys',
  component: () => import('@/views/cardsKeys/CardsKeysIndex.vue'),
  children: [
    {
      path: '',
      name: 'cards-keys',
      component: () => import('@/views/cardsKeys/CardsKeys.vue'),
      props: true,
      meta: {
        breadcrumbs: [{ text: 'routes.cards_keys', exact: true, to: { name: 'cards-keys' }, disabled: true }],
      },
    },
  ],
};
