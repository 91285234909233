import api from '@/api';
import { DEFAULT_SERVICE } from './constants';

export default {
  systemEmailsGet: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/system/email_servers/`, {
      params,
    });
  },
  systemEmailsSearch: async (params: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/system/email_servers/search/`, {
      params,
    });
  },
  systemEmailsGetOne: async ({ id }: any) => {
    return await api.get(`/${DEFAULT_SERVICE}/system/email_servers/${id}/`);
  },
  systemEmailsSet: async (params: any) => {
    return await api.post(`/${DEFAULT_SERVICE}/system/email_servers/`, params);
  },
  systemEmailsUpdate: async ({ id, ...params }: any) => {
    return await api.put(`/${DEFAULT_SERVICE}/system/email_servers/${id}/`, params);
  },
  systemEmailsDelete: async (params: Record<string, unknown>) => {
    return await api.delete(`/${DEFAULT_SERVICE}/system/email_servers/${params.id}/`);
  },
};
